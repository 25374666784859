.login {
  background-color: #fff;
  /* height: 100vh; */
  width: 100%;
}

.back-to-homepage {
  display: flex;
  margin-top: 0px;
  font-size: 15px;
  color: red;
  text-decoration-line: underline;
  align-items: center;
}

.loginpage_text {
  text-transform: uppercase;
  padding-top: 2rem;
  color: red;
  font-weight: 400;
  font-size: 20px;
  padding-left: 20px;
}

.login_form {
  width: 430px;
  /* height: 650px; */
  margin: 0 auto;
  margin-top: 6rem;
}

.form_text {
  text-transform: uppercase;
  color: red;
  text-align: center;
  font-family: "Styrene A Web Reg";
}

.main_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.UserName {
  width: 281px !important;
  height: 36px;
  border-radius: 10rem;
  border: 0.1px solid black !important;
  outline: none;
  font-family: "Styrene A Web Reg";
  padding: 20px;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

.passWord {
  width: 281px !important;
  height: 36px !important;
  border-radius: 10rem !important;
  border: 0.1px solid black !important;
  margin-bottom: 1rem;
  outline: none;
  font-family: "Styrene A Web Reg";
  padding: 20px;
}

.passWord33 {
  height: 36px !important;
  width: 100% !important;
  border-radius: 10rem !important;
  border: 0.1px solid black !important;
  margin-bottom: 1rem;
  outline: none;
  font-family: "Styrene A Web Reg";
  padding: 20px;
}

.login_button {
  width: 281px;
  height: 36px;
  border-radius: 10rem;
  border: 2px solid black;
  background-color: black;
  color: #fff;
  font-family: "Styrene A Web Reg";
}

.login_bottom {
  width: 95px;
  height: 11px;
  font-size: 11px;
  margin: 0 auto;
  text-align: center !important;
  font-family: "Styrene A Web Reg";
  margin-top: 0.7rem;
}

.forgot_pass {
  text-decoration: none;
  text-align: center;
  font-size: 9.5px;
  line-height: 2px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
  font-family: "Styrene A Web Reg";
  white-space: nowrap;
  cursor: pointer;
}

/* .register_bottom {
    height: 13px;
    width: 200px;
    text-align: center !important;
    position: absolute;
    left: 45.2%;
    margin: 0 auto;
} */

/* .register_user {
    text-align: center !important;
    font: normal normal normal 11px/2px Founders Grotesk;
    letter-spacing: 0.99px;
    color: #E22222;
    text-transform: capitalize;
    text-decoration: none;
}

.account_check {
    color: black;
    text-align: center !important;
    height: 13px;
    width: 200px;
} */

.register_user {
  font-size: 11px;
}

.account_check {
  color: black;
  font-size: 11px;
  font-family: "Styrene A Web Reg";
  padding-bottom: 1rem;
}

.register_bottom {
  text-align: center;
}

.social_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.google_login {
  width: 281px;
  height: 36px;
  border-radius: 10rem;
  border: 2px solid black;
  margin-bottom: 0.7rem;
  background-color: black;
  outline: none;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  background-image: url("../../assets/google.png");
  background-repeat: no-repeat;
  background-position: 30px 5px;
  background-size: 23px 20px;
}

.slack_login {
  width: 281px;
  height: 36px;
  border-radius: 10rem;
  border: 2px solid black;
  margin-bottom: 0.7rem;
  background-color: black;
  outline: none;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  background-image: url("../../assets/slack.png");
  background-repeat: no-repeat;
  background-position: 30px 5px;
  background-size: 23px 20px;
}

.email_login {
  width: 223px;
  height: 22px;
  text-align: center;
  margin-top: 29px;
  margin-bottom: 29px;
}

.email_login_text {
  letter-spacing: 0px;
  color: #bdbcba;
  text-transform: capitalize;
}

#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customBtn:hover {
  cursor: pointer;
}

.ray_logo {
  margin: 0 auto;
  height: 50px;
  width: 200px;
  object-fit: contain;
  background-size: cover;
}

/* responsive design */

@media (max-width: 480px) {
  .back-to-homepage {
    font-size: 11px !important;
  }
  .form_text {
    font-size: 13px !important;
  }
  .login_form {
    width: 370px;
    height: 590px;
    margin: 0 auto;
    margin-top: 4rem;
  }
  .google_login {
    width: 260px;
    height: 33px;
    background-position: 20px 5px;
    background-size: 23px 20px;
  }
  .slack_login {
    width: 260px;
    height: 33px;
    background-position: 20px 5px;
    background-size: 23px 20px;
  }
  .email_login {
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .UserName {
    width: 260px !important;
    height: 33px;
    border-radius: 10rem;
    border: 0.1px solid black !important;
    outline: none;
    padding: 17px;
  }
  .passWord {
    width: 260px !important;
    height: 33px !important;
    border-radius: 10rem;
    border: 0.1px solid black;
    margin-bottom: 1rem;
    outline: none;
    padding: 17px;
  }
  .login_button {
    width: 260px !important;
    height: 33px !important;
  }
}

@media (max-width: 786px) {
  .login_form{
    width: 90%;
    height: fit-content !important;
    margin-top: 2rem !important;
  }
}
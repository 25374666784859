.sus_container {
  height: 100%;
  width: 100%;
  background-color: #ecece8;
}

.sus_top {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sus_ttext {
  width: 200px;
  height: 38px;
  text-align: center;
  /* font: normal normal normal 11px/2px Founders Grotesk; */
  font-family: "Styrene A Web Reg";
  line-height: 25px;
  font-size: 11px;
  letter-spacing: 0.99px;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 0.16em;
}

.sus_mainImg {
  /* width: 90%; */
  width: 100%;
  height: 773px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainImg_head {
  width: 908px;
  height: 241px;
  text-align: center;
  font: normal normal normal 94px/121px Styrene A Web;
  letter-spacing: -3.48px;
  color: #ffffff;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 1;
}

.sus_grid {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  margin-top: 7rem;
}

.sus_grid1 {
  width: 497px;
  height: 251px;
  opacity: 1;
  /* background-color: blue; */
}

.sus_grid2 {
  width: 553px;
  height: 409px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 0.16em;
  text-decoration-thickness: 2px !important;
  font-weight: 600;
}

.sd_1 {
  width: 497px;
  height: 209px;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web Thin; */
  letter-spacing: 1px;
  font-family: Styrene A Web;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  opacity: 1;
  margin-top: 1rem;
}

.sd_icon {
  width: 38px;
  height: 21px;
  opacity: 1;
}

.vd_grid {
  width: 100%;
  height: 769px;
  background: transparent 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 1;
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  justify-content: flex-end;
}

.vd_tcontainer {
  height: 768px;
  width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-left: 1px solid #fff;
}

.vd_1,
.vd_2,
.vd_3,
.vd_4 {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  border-right: 1px solid #fff;
  /* justify-content: space-between; */
  justify-content: space-around;
}

.vd_1 {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.vd_2 {
  border-bottom: 1px solid #fff;
}

.vd_3 {
  border-right: 1px solid #fff;
}

.center_h1 {
  width: 200px !important;
  height: 8px !important;
  margin: 0 auto;
  margin-top: 7rem;
  text-align: center;
  /* font: normal normal normal 11px/2px Founders Grotesk; */
  /* font: normal normal normal 11px/22px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-weight: 500 !important;
  font-size: 11px !important;
  letter-spacing: 0.99px !important;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 0.16em;
  text-decoration-thickness: 2px !important;
}

.newML2{
  margin-left: .5rem;
}

.vd_a {
  width: 164px;
  height: 26px;
  text-align: left;
  /* font: normal normal medium 24px/1px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  line-height: 22px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 24px;
  /* letter-spacing: 0.05px; */
  color: #97cb95;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 2rem;
}

.vd_b {
  width: 300px;
  height: 115px;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Light";
  line-height: 22px;
  font-size: 16px;
  font-weight: 350;
  font-size: 18px;
  letter-spacing: 1px;
  color: #faf4ef;
  opacity: 1;
  margin-left: 2rem;
}

.vd_c {
  width: 304px;
  /* height: 26px; */
  text-align: left;
  /* font: normal normal medium 24px/1px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  line-height: 1px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 24px;
  /* letter-spacing: 0.05px; */
  color: #97cb95;
  text-transform: capitalize;
  opacity: 1;
  font-size: 24px;
  margin-left: 2rem;
}

.vd_d {
  width: 248px;
  height: 46px;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Light";
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 350;
  font-size: 18px;
  color: #faf4ef;
  opacity: 1;
  padding-bottom: 7rem;
  margin-left: 2rem;
}

.vd_e {
  width: 204px;
  height: 61px;
  text-align: left;
  /* font: normal normal medium 24px/29px Styrene A Web; */
  /* letter-spacing: 0.05px; */
  font-family: "Styrene A Web Reg";
  line-height: 29px;
  font-size: 24px;
  color: #97cb95;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 2rem;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 24px;
}

.vd_f {
  width: 260px;
  height: 114px;
  /* padding-top: -3rem; */
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Light";
  line-height: 22px;
  font-size: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
  margin-left: 2rem;
  font-size: 18px;
}

.vd_g {
  width: 164px;
  height: 61px;
  text-align: left;
  /* font: normal normal medium 24px/29px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  line-height: 29px;
  font-size: 24px;
  letter-spacing: 0.05px;
  color: #97cb95;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 2rem;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 24px;
}

.vd_h {
  width: 250px;
  height: 176px;
  opacity: 1;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Light";
  line-height: 22px;
  font-size: 16px;
  font-weight: 350;
  font-size: 16px;
  letter-spacing: 1px;
  color: #faf4ef;
  opacity: 1;
  margin-left: 2rem;
  font-weight: 350;
  font-size: 18px;
}

.fd_div {
  width: 280px;
  height: 13px;
  text-align: center;
  /* font: normal normal normal 11px / 2px Founders Grotesk; */
  font-family: "Styrene A Web Light";
  line-height: 22px;
  font-size: 13px;
  letter-spacing: 0.99px;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 7rem;
}

.fd_bottom {
  width: 804px;
  height: 175px;
  text-align: center;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 32px;
  margin: 0 auto;
  margin-top: 2rem;
}

.ray_cert {
  width: 100%;
  height: 823px;
  margin: 0 auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.ray_cert1 {
  width: 548px;
  height: 823px;
  background: #e22222 0% 0% no-repeat padding-box;
  opacity: 1;
}

/* .ray_cert1 img{
    width: 350px;
    height: 300px;
} */

.ray_gif {
  width: 278px;
  height: 278px;
}

.ray_cert2 {
  width: 100%;
  height: 823px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.cert2_a {
  width: 100%;
  height: 410px;
  background: transparent 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 1;
}

.cert2_b {
  width: 273px;
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.hc2_1 {
  width: 200px;
  height: 26px;
  /* padding-right: 200px; */
  font-family: "Styrene A Web Medium";
  text-align: left;
  font: normal normal medium 24px/1px Styrene A Web;
  letter-spacing: 0.05px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 24px;
}

.hc2_2 {
  width: 157px;
  height: 57px;
  text-align: left;
  padding-top: 15px;
  font: normal normal normal 22px/31px Cormorant Garamond;
  letter-spacing: -0.01px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.cert2_para {
  width: 200px;
  height: 93px;
  text-align: left;
  font: normal normal normal 16px/22px Styrene A Web;
  font-family: "Styrene A Web Reg";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cert2_c {
  width: 100%;
  height: 410px;
  background: transparent 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 1;
}

.cert2_d {
  width: 300px;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.hd2_1 {
  width: 200px;
  /* height: 26px; */
  /* margin-right: 50px; */
  text-align: left;
  font: normal normal medium 20px/1px Styrene A Web;
  font-family: "Styrene A Web Medium";
  letter-spacing: 0.05px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 20px;
}

.newPaddingLeft1{
  padding-left: 1.5rem;
}

.hd2_2 {
  width: 157px;
  height: 57px;
  padding-top: 15px;
  text-align: left;
  font: normal normal normal 22px/31px Cormorant Garamond;
  letter-spacing: -0.01px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 0.7rem;
}

.certd2_para {
  width: 200px;
  height: 222px;
  text-align: left;
  font: normal normal normal 16px/22px Styrene A Web;
  font-family: "Styrene A Web Reg";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cert2_e {
  width: 100%;
  height: 410px;
  background: transparent 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 1;
}

.cert2_f {
  width: 273px;
  height: 410px;
}

/* ============ */

.sus_carousal {
  width: 98%;
  height: 700px;
  /* background-color: cadetblue; */
  margin: 0 auto;
  margin-top: 1rem;
}

.caro_container {
  width: 98%;
  margin: 0 auto;
}

.cro_underline {
  height: 2px;
  width: 300px;
  background-color: #e22222;
}

.cro_content1 {
  width: 244px;
  height: 176px;
  text-align: left;
  font-family: Styrene A Web Reg;
  /* font: normal normal normal 16px/22px Styrene A Web Bold; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cro_content2 {
  width: 251px;
  height: 182px;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-size: 16px;
  font-family: Styrene A Web Reg;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cro_content3 {
  width: 251px;
  height: 158px;
  text-align: left;
  font-family: Styrene A Web Reg;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cro_content4 {
  width: 263px;
  height: 206px;
  font-family: Styrene A Web Reg;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cro_content5 {
  width: 256px;
  height: 158px;
  text-align: left;
  font-family: Styrene A Web Reg;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.carImg1,
.carImg2 {
  width: 300px;
  height: 410px;
  opacity: 1;
  object-fit: cover;
}

.btm_grid {
  width: 100%;
  height: 768px;
  /* margin: 0 auto; */
}

.btg_box1 {
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}

.btg_box2 {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}

.gd_ctn {
  width: 164px;
  height: 26px;
  text-align: left;
}

.gd_ctn h5 {
  /* font: normal normal medium 24px/1px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  line-height: 1px;
  font-size: 22px;
  letter-spacing: 0.05px;
  color: #000000;
  opacity: 1;
  width: 295px;
  height: 22px;
}

.gd_tempus {
  /* width: 260px; */
  height: 26px;
  text-align: left;
  font: normal normal normal 22px/1px Cormorant Garamond;
  letter-spacing: -0.01px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.gd_certified {
  width: 260px;
  height: 185px;
  text-align: left;
}

.gd_certified h6 {
  font: normal normal normal 16px/22px Styrene A Web;
  font-family: "Styrene A Web Reg";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.slick-prev {
  color: white !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
}

.jeans_container1 {
  width: 100%;
  height: 450px;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* responsive design */

@media screen and (max-width: 380px) {
  .mainImg_head {
    width: 340px !important;
    /* font-size: 20px !important; */
    height: 180px !important;
  }
}

@media screen and (max-width: 450px) {
  .sus_mainImg {
    height: 350px !important;
  }
  .mainImg_head {
    width: 500px !important;
    font-size: 50px !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 778px) {
  .sus_mainImg {
    height: 650px;
  }
  .mainImg_head {
    width: 720px;
    height: 230px;
    font-size: 80px;
  }
}

@media screen and (max-width: 1180px) {
  .sus_grid {
    width: 90%;
    margin-top: 4rem;
  }
  .sus_grid1 {
    width: 400px;
    height: 230px;
  }
  .sd_1 {
    width: 390px;
    height: 225px;
    text-align: left;
    font-size: 16px;
    margin-top: 0.5rem;
  }
  .sus_grid2 {
    width: 500px;
    height: 380px;
    font-size: 25px;
    margin-top: -0.5rem;
  }
  .sd_icon {
    margin-top: -2rem;
  }
}

/* .sus_grid {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    margin-top: 7rem;
}

.sus_grid1 {
    width: 497px;
    height: 251px;
    opacity: 1;
}

.sus_grid2 {
    width: 553px;
    height: 409px;
    text-align: left;
    font: normal normal normal 32px/49px Styrene A Web;
    letter-spacing: -0.02px;
    color: #000000;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 0.16em;
    text-decoration-thickness: 2px !important;
    font-weight: 600;
}

.sd_1 {
    width: 497px;
    height: 209px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web Thin;
    letter-spacing: 0px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    margin-top: 1rem;
}

.sd_icon {
    width: 38px;
    height: 21px;
    opacity: 1
} */

@media (max-width: 786px) {
  .mainImg_head{
    font-size: 24px !important;
    line-height: 35px;
    letter-spacing: 1px;
    height: fit-content !important;
    width: 80% !important;
  }
  .fd_div{
    margin-top: 60px;
  }
  .cert2_a{
    margin-bottom: 10px;
    height: 300px;
  }
  .cert2_c{
    margin-bottom: 10px;
    height: 300px;
  }
  .cert2_e{
    margin-bottom: 10px;
    height: 300px;
  }
  .ray_gif{
    height: 150px;
    width: 150px;
  }
  .sus_grid{
    display: block;
    margin-top: 25px !important;
  }
  .sus_grid1{
    width: 100%;
    height: fit-content;
    padding-bottom: 30px;
  }
  .sd_1{
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 10px;
  }
  .sus_carousal{
    height: fit-content;
  }
  .sus_mainImg{
    width: 100% !important;
    height: 230px !important;
  }
  .sus_grid2{
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    margin-top: 10px !important;
  }
  .fd_bottom{
    width: 80%;
    height: fit-content;
    margin-top: 20px;
    font-size: 18px;
    line-height: 30px;
  }
  .ray_cert{
    display: block;
    height: fit-content;
  }
  .ray_cert1{
    width: 100%;
    height: 200px;
  }
  .ray_cert2{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    min-height: 930px;
  }
  .hd2_1{
    text-align: left;
    width: 100%;
    font-size: 16px;
    padding-top: 20px;
  }
  .cert2_d{
    width: 100%;
    height: 300px;
    align-items: flex-start;
    padding-left: 10px;
  }
  .certd-header{
    height: 150px;
    padding-top: 20px;
  }
  .hd2_2{
    padding-top: 0px;
    text-align: left;
    width: 100%;
    font-size: 14px;
  }
  .certd2_para{
    width: 80%;
    height: fit-content;
    padding-bottom: 50px;
    padding-top: 0px;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
  }
  .cert2_f{
    width: 100%;
  }
  .btm_grid{
    width: 100%;
    height: fit-content;
    display: block;
  }
  /* .btg_box1{
    display: block;
  } */
  .gd_ctn{
    height: fit-content;
    margin-top: 30px;
    width: 100%;
  }
  .gd_tempus{
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  .gd_certified{
    width: 100%;
  }
  .gd_certified h6{
    font-size: 12px;
    width: 95%;
    text-align: center;
    margin-left: -0.5rem;
    padding-left: 2.5%;
  }
  .borderB2PX{
    /* border-bottom: 2px solid red; */
  }
  .vd_grid{
    height: auto;
    margin-top: 0rem;
  }
  .vd_tcontainer{
    width: 100%;
    height: fit-content;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
  }
  .vd_a{
    width: 100%;
    height: 50px;
    font-size: 14px;
    margin-left: 0px;
    /* padding-left: 15px; */
    text-align: center;
  }
  .vd_b{
    width: 90%;
    height: fit-content;
    font-size: 12px;
    line-height: 20px;
    margin-left: 5%;
    text-align: center;
    /* padding-left: 15px; */
  }
  .vd_c{
    width: 100%;
    height: 50px;
    font-size: 14px;
    margin-left: 0px;
    /* padding-left: 15px; */
    line-height: inherit;
    text-align: center;
  }
  .vd_d{
    width: 90%;
    height: fit-content;
    padding-bottom: 0px;
    font-size: 12px;
    margin-left: 5%;
    /* padding-left: 15px; */
    text-align: center;
  }
  .vd_e{
    width: 100%;
    height: 50px;
    margin-top: 0px !important;
    margin-left: 0px;
    /* padding-left: 15px; */
    font-size: 14px;
    text-align: center;
  }
  .vd_g{
    width: 100%;
    height: 50px;
    margin-top: 0px !important;
    font-size: 14px;
    /* padding-left: 15px; */
    margin-left: 0px;
    text-align: center;
  }
  .vd_h{
    width: 90%;
    height: fit-content;
    padding-top: 10px;
    font-size: 12px;
    padding-left: 0px;
    margin-left: 5%;
    text-align: center;
  }
  .vd_1{
    height: auto;
    padding: 20px 0px;
    width: 100%;
    justify-content: flex-start;
  }
  .vd_2{
    height: auto;
    padding: 20px 0px;
    width: 100%;
    justify-content: flex-start;
  }
  .vd_4{
    height: auto;
    padding: 20px 0px;
    border-bottom: 1px solid white;
    justify-content: flex-start;
  }

  .cro_content1{
    width: 90%;
    font-size: 10px;
    margin-top: 10px !important;
  }
  .cro_content2{
    width: 90%;
    font-size: 10px;
    margin-top: 10px !important;
  }
  .cro_content3{
    width: 90%;
    font-size: 10px;
    margin-top: 10px !important;
  }
  .cro_content4{
    width: 90%;
    font-size: 10px;
    margin-top: 10px !important;
  }
  .cro_content5{
    width: 90%;
    font-size: 10px;
    margin-top: 10px !important;
  }
  .newPaddingLeft1{
    padding-left: 0px !important;
  }
  .newML2{
    margin-left: 0px;
  }
  .gd_ctn h5{
    font-size: 14px !important;
    width: 100%;
    margin-left: 0px;
    text-align: center;
  }

}

.main_landing {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0 !important;
  /* background-color: black; */
  /* overflow-x: hidden; */
}

.heading_text {
  color: #e22222 !important;
  /* font-weight: 500; */
  font-size: 5rem;
  padding-top: 6rem;
  /* letter-spacing: -1px; */
  letter-spacing: 1px;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  font-family: "Styrene A Web Reg";
}

.heading1_main {
  width: 1222px;
  height: 241px;
  text-align: center;
  margin: 0 auto;
  font: bold normal 200 94px/121px Styrene A Web;
  /* letter-spacing: -1px; */
  letter-spacing: 1px;
  color: #e22222 !important;
  text-transform: capitalize;
  opacity: 1;
}

@media (max-width:1060px) {
  .heading1_main {
    width: 900px;
    height: 230px;
    text-align: center;
  }
  .heading_text {
    font-size: 4.5rem;
  }
}

@media (max-width:415px) {
  .heading_text {
    font-size: 3rem;
  }
}

.heading {
  margin: 0 auto;
  text-align: center;
  letter-spacing: -3.48px;
  color: #e22222 !important;
  text-transform: capitalize;
  /* position: relative; */
  opacity: 1;
  width: 100%;
  height: 90vh;
  background-color: black;
}

.main_slider1 {
  width: 100%;
  height: 520px;
  position: relative;
  top: 20%;
  margin-top: -140px;
}

.sl_main {
  width: 98%;
}

.sl_img1 {
  width: 348px;
  height: 464px;
  opacity: 1;
  margin-top: 2.5rem;
  padding-left: 1rem;
}

.sl_img2 {
  width: 348px;
  height: 464px;
  opacity: 1;
  padding-right: 1rem;
  padding-left: 1rem;
}

.sl_img3 {
  width: 348px;
  height: 464px;
  opacity: 1;
  padding-right: 1rem;
}

.sl_img4 {
  width: 309px;
  height: 464px;
  opacity: 1;
  margin-top: 2rem;
  padding-right: 1rem;
}

.sl_img5 {
  width: 348px;
  height: 464px;
  opacity: 1;
  padding-right: 1rem;
  padding-left: 1rem;
}

.sl_img6 {
  width: 348px;
  height: 464px;
  opacity: 1;
  padding-right: 1rem;
}

.sl_img7 {
  width: 309px;
  height: 464px;
  opacity: 1;
  margin-top: 2rem;
  padding-right: 1rem;
}

.sl_img3 {
  width: 348px;
  height: 464px;
  opacity: 1;
}

.image4 {
  /* top: 0px; */
  margin-top: 3rem;
  /* left: 1132px; */
  width: 309px;
  height: 464px;
  background: transparent url("../../assets/c4.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
  top: 40%;
}

.row {
  display: flex;
  padding-top: 100px;
}

.column {
  flex: 33.33%;
  padding: 10px;
}

.middle-column {
  padding-top: 2px;
}

hr {
  margin-left: auto;
  margin-right: auto;
  height: 4px;
  background-color: #e22222;
  outline: none;
}

.hr-pad {
  padding-top: 20px;
}

.hr-text {
  padding: 10px;
  color: #e22222;
  size: 16px;
}

.arrow {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 69px !important;
  height: 69px !important;
  /* background: #FAF4EF 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.arrow-pad {
  padding-top: 135px;
  padding-left: 48%;
  width: 69px !important;
  height: 69px !important;
  /* background: #FAF4EF 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.grid-container1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  padding-top: 3rem;
  grid-template-areas: ". . . .";
}

.cl_main {
  position: absolute;
  top: 400%;
}

.str_main {
  position: absolute;
  top: 560%;
}

.mn_footer {
  /* position: relative; */
  top: 720%;
  /* width: 100%; */
}

/* .ml_tb {
    position: absolute;
    top: 800%;
} */

.ln_rq {
  padding-top: 15rem;
}


@media (max-width: 786px) {
  .sl_img1{
    height: fit-content;
    width: 100%;
  }
  .sl_img2{
    height: fit-content;
    width: 100%;
  }
  .sl_img3{
    height: fit-content;
    width: 100%;
  }
  .sl_img4{
    height: fit-content;
    width: 100%;
  }
  .sl_img5{
    height: fit-content;
    width: 100%;
  }
  .sl_img6{
    height: fit-content;
    width: 100%;
  }
  .sl_img7{
    height: fit-content;
    width: 100%;
  }
  .sl_img8{
    height: fit-content;
    width: 100%;
  }
  .heading1_main{
    width: 90%;
    height: fit-content;
  }
  .arrow-pad{
    width: 100% !important;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    padding-top: 0px;
  }
  .heading_text{
    padding-top: 2rem;
    font-size: 25px;
  }
  .main_slider1{
    height: fit-content;
    margin-top: -60px;
  }
  .ln_rq{
    padding-top: 3rem;
  }
  .sl_main{
    width: 100%;
  }
  .heading{
    height: fit-content;
    padding-bottom: 80px;
  }
  .dashboardImageScroll{
    /* height: 200px !important; */
  }
}
.profileText{
  width: 100%;
  text-align: center;
  margin: 3% 0%;
  font-size: 25px;
  letter-spacing: 2px;
  font-weight: 700;
}
.personalText{
  width: 100%;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: bold;
  font-family: "Styrene A Web Reg BOLD";
}
.formMainViewProfile{
  width: 100%;
  padding: 0px 30%;
}
.labelProfile{
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 40px;
  letter-spacing: 1px;
  font-family: "Styrene A Web Reg";
}
.form_email2 {
  margin: 0 auto;
  align-items: flex-end;
  border-bottom: 1.5px solid #111;
}
.newFontFamily{
  font-family: "Styrene A Web Medium";
  font-size: 15px !important;
}
.twoInOneProfile{
  display: flex;
  align-items: center;
}
.changePassword{
  width: 100% !important;
  text-align: center;
  margin-top: -20px;
  padding-bottom: 30px;
  font-size: 14px;
  color: red;
  cursor: pointer;
}

@media (max-width: 786px) {
  .formMainViewProfile{
    padding: 0px 5%;
  }
}
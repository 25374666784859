/* Added fonts here use import statement in .js or .jsx and give corrent src of the font*/

/* Bold font */

@font-face {
    font-family: 'Styrene A Web Reg';
    src: local('Styrene'), url(../../assets/font/Reg.ttf) format('truetype');
}

@font-face {
    font-family: 'Styrene A Web Black';
    src: local('FiraSans'), url(../../assets/font/StyreneAWeb-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Styrene A Web Light';
    src: local('FiraSans'), url(../../assets/font/StyreneAWeb-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Styrene A Web Medium';
    src: local('FiraSans'), url(../../assets/font/StyreneAWeb-Medium.ttf) format('truetype');
}

.popup_close {
    /* width: 37px; */
    /* height: 36px; */
    width: 40px;
    height: 40px;
    /* border: 2px solid #111; */
    opacity: 1;
    /* border-radius: 10rem;
    color: rgb(8, 8, 8); */
    margin-top: 25px;
    cursor: pointer;
}

.modal_header {
    width: 241px;
    height: 117px;
    text-align: center;
}

.clo_btn {
    font-size: 33px;
}

.modal_header h6 {
    width: 241px;
    height: 117px;
    /*font: bold normal 200 50px/53px Styrene A Web;*/
    font-size: 50px;
    font-family: 'Styrene A Web Reg';
    letter-spacing: -1.03px;
    color: #E22222;
    text-transform: capitalize;
    opacity: 1;
}

.modal_para {
    width: 361px;
    height: 263px;
    text-align: center;
}

.modal_para h6 {
    font: bold normal 100 16px/24px Styrene A Web;
    letter-spacing: 1px;
    line-height: -2px;
    color: #000000;
    opacity: 1;
}

.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

.mov {
    height: 32px;
    width: 32px;
}
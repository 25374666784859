/* page styling */

.about_container {
  width: 100%;
  height: 100%;
  background: #faf4ef 0% 0% no-repeat padding-box;
}

.ourJourneyText{
  font-size: 30px;
  letter-spacing: 1px;
  font-family: "Styrene A Web Medium";
  width: 100%;
  text-align: center;
  background-color: #eeefed;
  padding-top: 30px;
}

.about {
  width: 95%;
  height: 90vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* background: #FAF4EF 0% 0% no-repeat padding-box; */
}

.acs_today {
  width: 644px;
  height: 241px;
  text-align: left;
  font: normal normal normal 84px/121px Styrene A Web;
  font-family: "Styrene A Web Reg";
  letter-spacing: -3.48px;
  color: #e22222;
  text-transform: capitalize;
  /* opacity: 1; */
}

.digital {
  width: 505px;
  height: 92px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  font-family: "Styrene A Web Reg";
  text-underline-offset: 0.16em;
  text-decoration-thickness: 2px;
  letter-spacing: -0.02px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  text-decoration: underline;
}

.about-right {
  margin-top: 2rem;
}

.abr_1 {
  width: 215px;
  height: 160px;
  opacity: 1;
}

.abr1_text {
  width: 215px;
  height: 116px;
  text-align: left;
  font-family: "Styrene A Web Reg";
  font-size: 16px;
  line-height: 22px;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.abr1_img {
  width: 38px;
  height: 21px;
  opacity: 1;
}

.abr_2 {
  width: 215px;
  height: 222px;
  opacity: 1;
}

.abr2_text {
  width: 215px;
  height: 181px;
  text-align: left;
  font-family: "Styrene A Web Reg";
  font-size: 16px;
  line-height: 22px;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media (max-width: 1150px) {
  .about {
    height: 50vh;
  }
  .about-left {
    height: 600px;
    width: 100%;
  }
  .about-right {
    height: 600px;
    width: 100%;
  }
}

@media (max-width: 880px) {
  .about {
    height: 50vh;
    height: 550px;
  }
  .acs_today {
    width: 550px;
    letter-spacing: -3.48px;
    height: 210px;
    /* font-size: 80px; */
    font: normal normal normal 75px/121px Styrene A Web;
  }
  .about-right {
    margin-top: 0;
  }
  .abr_1 {
    margin-top: 2rem;
  }
}

@media (max-width: 795px) {
  .about {
    margin: 0 auto;
    grid-gap: 0;
    height: 550px;
  }
  .about-left {
    width: 95%;
    height: 550px;
  }
  .about-right {
    margin-right: 2rem;
    height: 550px;
  }
  .acs_today {
    width: 500px;
  }
}

@media (max-width: 550px) {
  .about {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
  .about-left {
    height: 400px;
  }
  .about-right {
    margin-top: 0;
  }
}

.access {
  /* height: 100%; */
  display: grid;
  grid-template-columns: auto auto;
  background: #faf4ef 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 2rem;
}

.access_div1, .access_div2, .access_div3, .access_div4 {
  background: #faf4ef 0% 0% no-repeat padding-box;
  /* border: 1px solid rgba(196, 89, 89, 0.8); */
}

.access_div1 {
  margin-top: 193px;
  margin-left: 123px;
  width: 620px;
  height: 241px;
}

.access_div1 h1 {
  text-align: left;
  font: normal normal normal 94px/121px Styrene A Web;
  letter-spacing: -5px;
  font-size: 7rem;
  color: #e22222;
  font-weight: 500;
  text-transform: capitalize;
  opacity: 1;
}

.access_div2 {
  margin-top: 212px;
  width: 215px;
  height: 116px;
  margin-right: 15rem;
}

.access_div2 p {
  text-align: left;
  font: normal normal normal 16px/22px Styrene A Web;
  letter-spacing: 1.5px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
}

.arrow_logo {
  width: 38px;
  height: 21px;
  margin-top: 30px;
}

.div3_headText {
  margin-top: 45px;
  margin-left: 130px;
  width: 505px;
  height: 92px;
}

.div3_headText h3 {
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: 2px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 32px;
  text-decoration: underline;
}

.register_div {
  width: 281px;
  height: 36px;
  margin-top: 50px;
  margin-left: 130px;
}

.div4_headText {
  width: 215px;
  height: 181px;
}

.div4_headText p {
  text-align: left;
  font: normal normal normal 16px/22px Styrene A Web;
  letter-spacing: 2px;
  color: #000000;
  opacity: 1;
}

.center_head {
  width: 250px;
  height: 8px;
  text-align: center;
  /* font: normal normal normal 11px/2px Founders Grotesk; */
  font-family: "Styrene A Web Reg";
  font-size: 11px;
  line-height: 2px;
  text-decoration: underline;
  text-underline-offset: 0.16em;
  /* letter-spacing: 0.99px; */
  font-size: 11px;
  color: #000000;
  opacity: 1;
  margin: 0 auto;
  padding-top: 6rem;
}

/* .center_underline {
    height: 2px;
    width: 125px;
    margin: 0 auto;
    background-color: #111;
    margin-top: 8px;
} */

.center_head1 {
  width: 400px;
  height: 26px;
  text-align: center;
  /* font: normal normal medium 24px/1px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  line-height: 1px;
  font-size: 24px;
  /* letter-spacing: 0.05px; */
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin: 0 auto;
  margin-top: 4.7rem;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 24px;
}

.center_head2 {
  width: 270px;
  height: 26px;
  text-align: center;
  font: normal normal normal 22px/1px Cormorant Garamond;
  letter-spacing: -0.01px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin: 0 auto;
  font-size: 22px;
  margin-top: 1.2rem;
  font-weight: 200;
}

.center_head3 {
  width: 804px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
  margin-top: 1.8rem;
  margin-bottom: 7rem;
}

.center_head3 h3 {
  /* font: normal normal normal 32px/49px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 32px;
  line-height: 49px;
  letter-spacing: 3px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.grid_layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . . . ." ". . . . .";
  padding-top: 30px;
  background-color: #eeefed;
}

.grid_div {
  width: 100%;
  height: 420px;
}

.grid_div img {
  height: 420px;
  width: 100%;
}

/* .grid_text1{
display: flex;
flex-direction: column;
justify-content: space-between;
text-align: center;
}
.grid_text1 h1{
    margin-top: 1rem;
    margin-bottom: 1rem;
} */

.access_center {
  background: #faf4ef 0% 0% no-repeat padding-box;
}

.access_bottom {
  width: 100%;
  /* height: 360px; */
  /* background: #FAF4EF 0% 0% no-repeat padding-box; */
  background-color: #eeefed;
  opacity: 1;
  /* padding-top: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.access_bText {
  width: 900px;
  /* height: 99px; */
  text-align: center;
  /* font: normal normal normal 32px/49px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 30px;
  line-height: 49px;
  letter-spacing: -0.02px;
  text-underline-offset: 0.16em;
  text-decoration: underline;
  margin: 0 auto;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 50px;
}

.access_bImg {
  width: 60px;
  margin: 0 auto;
  height: 60px;
  background: #000000 0% 0% no-repeat padding-box;
  background-color: transparent;
  opacity: 1;
  padding-top: 1rem;
}

.access_bImg img {
  width: 65px;
  height: 65px;
  background-color: transparent;
}

/* .grid_txt1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.gt1_txt1{
    width: 132px;    
    height: 26px;
    text-align: left;
    font: normal normal medium 24px/1px Styrene A Web;
    letter-spacing: 0.05px;
    color: #837d7d;
    text-transform: capitalize;
    opacity: 1;
    margin-top: 1rem;
} */

.grid_text1 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.year1 {
  width: 132px;
  height: 26px;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 20px;
  /* font: normal normal medium 24px/1px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 24px;
  line-height: 1px;
  letter-spacing: 0.05px;
  color: gray;
  text-transform: capitalize;
  opacity: 1;
}

.fame_text {
  /* width: 200px; */
  margin-top: 0.9rem;
  height: 93px;
  text-align: left;
  font-family: "Styrene A Web Medium";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.fmm {
  font-family: "Styrene A Web Medium";
  letter-spacing: 0px;
  text-align: left;
  /* margin-top: 0.9rem; */
  color: #000000;
  opacity: 1;
  width: 230px;
}

.gt1_txt1, .gt1_text2 {
  margin: 0 auto;
}

.gt_d5 {
  margin-top: 2rem;
}

.access_footer {
  background-color: #ecece8;
  padding-top: 3rem;
}

.acc_req {
  margin-top: 6rem;
}

/* responsive design */

@media (max-width: 480px) {
  .center_head3 {
    width: 380px;
    height: 170px;
    color: blanchedalmond;
  }
  .center_head3 h3 {
    letter-spacing: 1px;
    font-size: 18px;
    text-transform: capitalize;
    opacity: 1;
  }
  .center_head1 {
    width: 330px;
    height: 23px;
    margin-top: 4rem;
    font-size: 18px;
  }
  .center_head2 {
    width: 250px;
    height: 23px;
    font-size: 15px;
    margin-top: 1rem;
  }
  .access_bText {
    width: 400px;
    /* height: 80px; */
    letter-spacing: -0.01px;
    font-size: 18px;
    margin-bottom: 0.7rem;
  }
  .cn2 {
    padding-top: 3rem;
  }
}

@media (max-width: 786px) {
  .center_head3{
    height: fit-content;
    width: 90%;
    padding-left: 5%;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .year1{
    font-size: 14px;
    height: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .fame_text{
    font-size: 12px;
    height: fit-content !important;
  }
  .fmm{
    font-size: 12px;
    width: 100%;
    height: fit-content !important;
  }
  .grid_text1{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .access_bText{
    width: 90%;
    padding: 20px 0px;
    height: fit-content;
    margin: 0px;
    font-size: 14px;
    line-height: 28px;
  }
  .grid_div{
    min-width: 100% !important;
    height: fit-content;
  }
  .grid_div img{
    height: auto;
    width: 100%;
    margin-bottom: 10px !important;
  }
  .grid_layout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: ". ." ". ." ". ." ". ." ". .";
    width: 100%;
  }
  .center_head1 {
    margin-top: 40px !important;
  }
  .center_head2 {
    margin-top: 10px !important;
  }
  .gt1_text2{
    margin-top: 0px !important;
    padding-bottom: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .gt1_txt1{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .center_head3 h3 {
    letter-spacing: 0px;
    font-size: 12px;
    text-transform: capitalize;
    opacity: 1;
    line-height: 18px;
  }
  .ourJourneyText{
    padding-top: 0px;
    font-size: 20px;
  }
}
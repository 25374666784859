.footer {
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
    /* height: 706px; */
    background: #0F0F0F 0% 0% no-repeat padding-box;
    opacity: 1;
}

.foot_news {
    width: 58px;
    height: 15px;
    text-align: center;
    /* font: normal normal normal 11px/2px Founders Grotesk; */
    font-family: "Styrene A Web Reg";
    font-size: 11px;
    line-height: 2px;
    letter-spacing: 0.99px;
    color: #E22222;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 0.4em;
    text-decoration-thickness: 2px;
}

.foot_mail {
    width: 281px;
    height: 36px;
    border: 2px solid #FAF4EF;
    border-radius: 18px;
    background-color: #111;
    opacity: 1;
    color: #fff;
}

form.input_icons {
    width: 281px;
    height: 36px;
    border: 2px solid #FAF4EF !important;
    border-radius: 18px;
    background-color: #111;
    opacity: 1;
    color: #fff;
}

.foot_subss {
    max-width: 363px;
    /* height: 93px; */
    text-align: center;
    /* font: normal normal normal 32px/49px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 32px;
    line-height: 49px;
    letter-spacing: -0.02px;
    color: #FAF4EF;
    text-transform: capitalize;
}

.foot_1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 60px;
}

.foot2_container {
    margin: 0 auto;
    width: 100%;
}

.footer_menus {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid red;
    padding-bottom: 20px;
}

.foot_rights {
    width: 240px;
    height: 6px;
    text-align: center;
    font: normal normal normal 11px/2px Founders Grotesk;
    letter-spacing: 0.99px;
    color: #E22222;
    opacity: 1;
}

.input_icons1 {
    width: 28px;
    height: 28px;
    /* position: absolute; */
    left: 57%;
    margin-top: 28px;
    margin-left: -33px;
}

.foot_mail::placeholder {
    padding-left: 5rem;
}

.foot_about {
    /* width: 97px; */
    height: 20px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #FAF4EF;
    opacity: 1;
}

.foot_contact {
    /* width: 125px; */
    height: 20px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #FAF4EF;
    opacity: 1;
}

.foot_tc {
    /* width: 204px; */
    height: 20px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #FAF4EF;
    opacity: 1;
}

.foot_pp {
    /* width: 204px; */
    height: 20px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #FAF4EF;
    text-transform: uppercase;
    opacity: 1;
}

.foot_sus {
    /* width: 159px; */
    height: 20px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #FAF4EF;
    opacity: 1;
}

.foot_fq {
    /* width: 45px; */
    height: 20px;
    text-align: left;
    /* font: normal normal normal 16px/22px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #FAF4EF;
    opacity: 1;
}

.foot_conn {
    /* width: 186px; */
    height: 20px;
    text-align: left;
    /* font: normal normal normal 16px/22px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #FAF4EF;
    opacity: 1;
}

.foot_left {
    margin-left: 0rem;
}

.foot_right {
    height: 80%;
    margin-top: 2rem;
    margin-right: 5rem;
    width: 350px;
}

.footer_copy {
    max-width: 100vw;
    margin-top: 15px;
    height: 10px;
    text-align: center;
    font: normal normal normal 11px/2px Founders Grotesk;
    /* font-family: "Styrene A Web Reg";
    font-size: 11px;
    line-height: 2px; */
    letter-spacing: 0.99px;
    color: #E22222;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 0.35em;
}

.foo_ab {
    color: #FAF4EF !important;
    font-family: "Styrene A Web Reg";
}

.socialImage1{
    margin-right: 3rem;
}

.foo_ab:hover {
    font-size: 14px;
    color: #fa5d5d !important;
    transition: all 0.3s ease-in-out;
}

.top_button {
    width: 69px;
    height: 69px;
    opacity: 1;
    position: relative;
    left: 100vw;
    margin-left: -95px;
}

.top_button img {
    cursor: pointer;
}

.row-view{
    display: flex;
    justify-content: space-around;
}

.extraPadding{
    padding: 30px 0px;
}
.foot_logos{
    display: flex;
}

@media(max-width:678px) {
    .row-view{
        display: block;
    }
    .extraPadding{
        text-align: center;
    }
    .foot_logos{
        justify-content: center;
    }
}

@media(max-width:950px) {
    .input_icons1 {
        left: 61.2%;
    }
}

@media(max-width:1040px) {
    .input_icons1 {
        left: 60%;
    }
}

@media(max-width:1220px) {
    /* .input_icons1 {
        left: 56.2%;
    } */
}

@media(max-width:1440px) {
    .input_icons1 {
        left: 57.5%;
    }
}

@media(max-width:786px) {
    .foo_ab{
        font-size: 12px;
    }
    .foot_mail{
        width: 65vw;
        font-size: 14px;
    }
    .foot_mail::placeholder {
        padding-left: 0px !important;
    }
    .foot_conn{
        font-size: 12px;       
    }
    .extraPadding{
        padding: 15px 0px;
    }
    .foot_logos{
        margin-top: 10px;
    }
    .socialImage1{
        margin-right: 20px;
    }
    .foot_subss{
        font-size: 18px;
        line-height: 25px;
    }
    .foot_1{
        padding: 20px;
    }
    .top_button{
        /* margin-top: 20px !important; */
        height: fit-content;
        margin-bottom: -20px;
        margin-left: -60px;
    }
    .input-icons{
        width: fit-content;
    }
}
.tc{
    width: 85%;
    margin: 0 auto;
    font-family: 'Styrene A Web Reg';
    font-size: 20px;
}

@media (max-width: 786px) {
    .tc{
        font-size: 12px;
    }
    .tc h1{
        font-size: 15px;
    }
}
.l_main {
    width: 90%;
    height: 80vh;
    display: grid;
    position: absolute;
    /* top: 150%; */
    top: 170%;
    left: 5%;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    /* margin-top: 1rem; */
    margin-top: 10rem;
}

.ls_1 {
    background-color: #F0F0F0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.ls_1 img {
    width: 404px;
    height: 565px;
    object-fit: cover;
    margin-left: 0.7rem;
}

.ls_2 {
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.l2_title {
    width: 610px;
    height: 240px;
    text-align: center;
}

.l2_title h2 {
    font: normal normal normal 70px/75px Styrene A Web;
    letter-spacing: -2.59px;
    color: #E22222;
    text-transform: capitalize;
    opacity: 1;
    font-size: 70px;
}

.l2_sub {
    width: 382px;
    height: 25px;
    text-align: center;
    font: normal normal medium 24px/1px Styrene A Web;
    letter-spacing: 0.05px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
    padding-top: 1rem;
}

.l2_para {
    width: 357px;
    height: 111px;
    text-align: center;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-top: 2rem;
}
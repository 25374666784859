.collections {
  background-color: #f0f0f0;
}

.collection_heading {
  color: red;
  font-weight: 300;
  font-size: 6rem;
  letter-spacing: -3.48px;
}

.head_text1 {
  color: #e22222;
  text-align: center;
  letter-spacing: 2.59px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 450;
  font-size: 4rem;
  padding-top: 60px;
}

.subhead_text {
  padding-top: 20px;
  color: #111;
  font-weight: 500;
}

.collection_subhead {
  text-align: center;
}

.arrow-button {
  padding-top: 25px;
  padding-bottom: 20px;
}

.arrow2 {
  margin: 0 auto;
  width: 50px;
  height: 50px;
}

.collection_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 4rem;
  /* height: 100px; */
}

.content_text {
  /* background-color: red; */
  text-align: center;
  margin-top: 8rem;
}

.border_line {
  width: 25%;
  height: 2px;
  margin: 0 auto;
  background-color: black;
}

.content_images {
  /* background-color: aqua; */
  display: flex;
  justify-content: flex-end;
}

.founder_text {
  color: #111;
  font-weight: 500;
}

.content_heading {
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  margin-top: 2rem;
}

.content_subhead {
  font-size: 1.5rem;
}

.content_para {
  font-weight: 500;
  letter-spacing: 1px;
  line-height: -2px;
  font-size: 16px;
}

.para_div {
  width: 74%;
  margin: 0 auto;
  margin-top: 2rem;
}

.main_image {
  margin-right: 5rem;
  width: 500px;
  height: 749px;
}

.second_image {
  position: absolute;
  z-index: 1000;
  left: 57%;
  margin-top: 14rem;
  width: 305px;
  height: 458px;
}

.collection_bottom {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: 0 auto;
  margin-top: 8rem;
  text-align: center;
}

.first_bottom {
  width: 210px;
  height: 140px !important;
  /* background-color: rgb(180, 82, 82); */
  /* text-align: left; */
  text-align: center;
  margin-left: 7rem;
}

.second_bottom {
  /* background-color: rgb(165, 180, 82); */
}

.third_bottom {
  text-align: left;
  width: 142px;
  height: 189px;
  /* padding-top: -1rem; */
  /* background-color: rgb(82, 113, 180); */
}

.center_content {
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: 500;
  padding-top: 4rem;
  width: 804px;
  height: 175px;
  text-align: center;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.vert_image {
  height: 250px;
}

.hz_image {
  /* padding-top: 4rem; */
  margin-top: 6rem;
  /* margin-right: 3rem; */
  width: 210px;
  height: 140px;
}

.last_border {
  height: 2px;
  width: 90%;
  background-color: red;
  margin: 0 auto;
  margin-top: 15rem;
}

.number-row1 {
  display: block;
  padding-top: 80px;
  width: 184px;
  height: 8px;
  margin: 0 auto;
  margin-left: 21rem;
}

.number-column {
  flex: 5%;
}

.explore_more {
  width: 281px;
  height: 36px;
  border-radius: 18px;
  opacity: 1;
  /* margin-right: -20rem; */
  margin: 0 auto;
  margin-top: 4rem;
}

/* =============================================================== */

/* animations */

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.zoom_icon {
  width: 69px;
  height: 69px;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  opacity: 1;
  position: relative;
  left: 42%;
  top: 22%;
}

/* Responsive design */

@media (max-width:768px) {
  .head_text1 {
    font-size: 2rem;
    letter-spacing: 0;
  }
  .subhead_text {
    font-size: 0.7rem;
  }
  .arrow2 {
    height: 35px;
    width: 35px;
  }
  .collection_content {
    display: flex;
    flex-direction: column-reverse;
  }
  .main_image {
    margin-right: 5rem;
    width: 260px;
    height: 369px;
  }
  .second_image {
    width: 170px;
    height: 230px;
    /* position: absolute; */
    z-index: 1000;
    /* left: 57%; */
    /* right: 18%; */
    /* margin-top: 14rem; */
  }
  .founder_text {
    font-size: 0.7rem;
  }
  .number-row1 {
    display: block;
    padding-top: 50px;
    width: 130px;
    height: 8px;
    margin: 0 auto;
    margin-left: 6rem;
  }
  .arrow-button{
    padding-top: 0px;
    padding-bottom: 15px;
  }
}
.checkout {
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.ck_head {
    display: flex;
    justify-content: center;
}

.ck_head h1 {
    margin-top: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.8);
}
.minWidth200{
    min-width: 200px;
}

.ck_details {
    width: 75%;
    margin: 0 auto;
    margin-top: 3rem;
    min-width: 200px;
    padding-bottom: 15px;
}

.tb_img {
    height: 150px;
    width: 100px;
    object-fit: contain;
    /* background-size: cover; */
}

.td_sum {
    width: 500px;
}

.tb_hd th {
    font-weight: 400;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.7);
}

.ck_btn {
    width: 200px;
    height: 40px;
    background-color: #000000;
    color: #fff;
}

.ck_btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s ease-in-out;
}

.ck_hang {
    width: 100px;
    height: 40px;
    border: 1px solid black;
    display: flex;
    justify-content: space-around;
}

.ck_hang button {
    width: 20px;
}

.tr_btn {
    color: rgba(0, 0, 0, 0.7);
}

.tr_btn:hover {
    color: red;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}
.mainProductView{
    display: flex;
}
.nameAndDelete{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media(max-width:786px) {
    .ck_details{
        width: 90%;
        margin-top: 20px;
    }
    .minWidth200{
        min-width: 20px;
    }
    .mainProductView{
        display: block;
    }
    .MuiButton-label{
        justify-content: flex-start !important;
    }
    .productTextCart{
        margin-left: 0px !important;
        font-size: 15px !important;
        text-align: center !important;
        font-family: Styrene A Web Reg;
    }
    .tb_img{
        width: 100%;
    }
    .cs_desc h5{
        font-size: 14px;
    }
    .htextPop{
        font-size: 12px;
    }
    .ytextPop{
        font-size: 12px;
    }
    .cs_btn{
        font-size: 14px !important;
    }
    .ck_btn{
        font-size: 14px !important;
    }
    .ck_head h1{
        font-size: 18px;
    }
    .ck_hang{
        width: 65px;
    }
    .tableHead22{
        font-size: 12px;
        text-align: center;
    }
}
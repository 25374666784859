.landing_collection {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 2rem;
  margin-top: 3rem;
}

.ts_text {
  /* width: 700px;
  height: 90px; */
  text-align: center;
  margin: 0 auto;
}

.ts_p {
  /* font: normal normal normal 70px/81px Styrene A Web; */
  font-size: 60px;
  line-height: 75px;
  font-family: "Styrene A Web Reg";
  /* font-family: 'Styrene'; */
  letter-spacing: 1.5px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
}

.ts_para {
  width: 600px;
  /* height: 47px; */
  text-align: center;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 auto;
  font-size: 17px;
  line-height: -1px;
  font-family: "Styrene A Web Medium";
}

.lnc_btmsection {
  width: 100%;
  height: 760px;
  margin-top: 3rem;
}

.sm_image {
  width: 305px;
  height: 458px;
  opacity: 1;
  position: absolute;
  right: 30%;
  margin-top: 12rem;
}

.hero1_img {
  width: 500px;
  height: 749px;
  opacity: 1;
  margin-left: 0;
}

.btc_parent {
  margin-top: 9rem;
}

.btc_founder {
  width: 250px;
  height: 18px;
  text-align: center;
  /* font: normal normal normal 11px/2px Founders Grotesk; */
  font-family: "Styrene A Web Medium";
  font-size: 11px;
  line-height: 2px;
  letter-spacing: 0.99px;
  color: #000000;
  opacity: 1;
}

.btc_forest {
  width: 200px;
  height: 26px;
  text-align: center;
  /* font: normal normal medium 24px/1px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  font-size: 24px;
  line-height: 1px;
  letter-spacing: 0.05px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin: 0 auto;
  margin-top: 2.7rem;
}

.btc_gander {
  width: 294px;
  height: 26px;
  text-align: center;
  font: normal normal normal 22px/1px Cormorant Garamond;
  font-weight: 500;
  letter-spacing: -0.01px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 1rem;
}

.btc_para {
  width: 484px;
  height: 111px;
  text-align: center;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 1.3rem;
}

.btc_btn {
  margin-top: 3rem;
}

.jeans_container {
  width: 100%;
  height: 450px;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jn_founder {
  width: 165px;
  height: 8px;
  text-align: center;
  /* font: normal normal normal 11px/2px Founders Grotesk; */
  font-family: "Styrene A Web Reg";
  font-size: 11px;
  line-height: 2px;
  letter-spacing: 0.99px;
  color: #000000;
  opacity: 1;
}

.jn_para {
  width: 804px;
  height: 175px;
  text-align: center;
  /* font: normal normal normal 32px/49px Styrene A Web; */
  font-weight: 450;
  font-size: 32px;
  letter-spacing: 1px;
  font-family: "Styrene A Web Reg";
  /* letter-spacing: -0.02px; */
  /* color: #000000; */
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  opacity: 1;
  margin-top: 2rem;
}

.jn_btm {
  width: 1250px;
  /* height: 350px; */
}

.jn_img2 {
  width: 142px;
  height: 189px;
}

.jm_img1 {
  width: 210px;
  height: 140px;
  margin-top: 8rem;
}



@media (max-width: 786px) {
  .jn_btm{
    display: block;
    width: 100%;
    justify-content: flex-start;
  }
  .jm_img1{
    width: 90%;
    margin-left: 5%;
    height: fit-content;
    margin-top: 40px;
  }

  .leafImage{
    height: auto;
    display: none;
  }
  .jn_img2{
    width: 90%;
    margin-left: 5%;
    padding-bottom: 50px;
    height: fit-content;
    display: none;
  }
  .jeans_container{
    height: fit-content;
  }
  .jn_founder{
    height: fit-content;
    margin-top: 50px;
  }
  .jn_para{
    height: fit-content;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 30px;
    font-size: 14px;
  }
  .ts_para{
    width: 90%;
    margin-left: 5%;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .lnc_btmsection{
    width: 100%;
    height: fit-content;
    display: block;
    margin-top: 0px;
  }
  .btc_parent{
    margin-top: 0px;
  }
  .btc_para{
    width: 90%;
    margin-top: 0px;
    font-size: 12px;
    font-family: "Styrene A Web Reg";
    letter-spacing: 0.1px;
    line-height: 20px;
  }
  .btc_btn{
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .hero1_img{
    height: 250px;
    width: auto;
  }
  .sm_image{
    display: none;
  }
  .btm_images{
    justify-content: center !important;
  }
  .ts_p{
    font-size: 20px;
    line-height: 20px;
  }
  .landing_collection{
    margin-top: 0px;
  }
}
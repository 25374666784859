
.collection-name{
    text-align:center;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}

.body,img {
    width: 100%;
}

.image-container{
    position: relative;
    text-align: center;
}
.tagline{
    position: absolute;
    bottom: 65%;
    left: 45%;
    color: white;
    font-size: 15px;
}
.headline{
    color:red;
    font-size: 2.2rem;
    position: absolute;
    bottom: 57%;
    left: 35%;
}
sup{
    font-size:10px
}
.paragraph{
    position: absolute;
    color: white;
    font-size: 15px;
    bottom: 46%;
    left: 30%;
    width:380px;
}

.item-list{
    padding-top: 40px;
    color:red;
    display: flex;
    gap: 19px;
    justify-content: center;
}
.explaination{
    padding-top: 10px;
    padding-bottom: 40px;
    width: 1000px;
    position: relative;
    left: 60px;
    text-align: center;
}

.products{
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
}

/* .discover-collection{
    padding-top: 10px;
    padding-bottom: 40px;
    width: 1000px;
    position: relative;
    left: 60px;
    text-align: center;
} */

.headline-1{
    padding-top: 10px;
    text-align: center;
}
.paragraph-1{
    padding-top: 20px;
    padding-bottom: 40px;
    width: 1000px;
    position: relative;
    left: 80px;
    text-align: center;
}

.container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 30px;
}

.left-container-image{
    width:70%;
    margin-left: 63px;
}
.right-container{
    display: grid;
    gap: 25px;
}

input.input {
    height: 2rem;
    width: 19rem;
    border-radius: 25px;
    outline: none;
    padding: 20px;
    text-align: center;
}

.right-container-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
}
.all-logos{
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logos{
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    gap:30px;
}

.dropdown-menu{
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.dropdown-left{
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
}
.dropdown-right{
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}
svg {
    font-size: 27px;
}

.dropdown-products{
    padding-top: 20px;
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
}
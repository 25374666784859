.loading_gif {
    height: 100vh;
    width: 100%;
    background-color: black;
    color: red;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Styrene A Web Medium";
}

.gif_div {
    width: 253px;
    height: 400px;
    opacity: 1;
    /* margin: 0 auto; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 10rem; */
}

.gif_div {
    width: 260px;
    height: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.gif_container img {
    width: 240px;
    height: 365px;
    object-fit: contain;
    background-size: cover;
}

.load_text {
    width: 500px;
    /* height: 26px; */
    text-align: center;
    font: normal normal normal 20px/121px Styrene A Web;
    letter-spacing: -0.74px;
    color: #E22222;
    text-transform: capitalize;
    opacity: 1;
}

.cp_rights {
    /* height: 10px; */
    text-align: center;
    font: normal normal normal 11px/2px Founders Grotesk;
    letter-spacing: 0.99px;
    color: #E22222;
    opacity: 1;
    text-decoration: underline;
}

.l_text {
    font-family: Styrene A Web;
    font-size: 16px;
    color: #E22222;
    font-weight: 600;
}

.cp_right {
    font-family: Founders Grotesk;
    font-size: 11px;
    text-decoration: underline;
    color: #E22222;
    font-weight: 600;
}

.uco {
    width: 250px;
    height: 45px;
    /* margin-top: 3rem; */
    background-size: cover;
    object-fit: contain;
}

.main_loading {
    width: 500px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.gif_img {
    width: 244px;
    height: 367px;
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.gif_img img {
    width: 240px;
    height: 365px;
    object-fit: contain;
    background-size: cover;
}

@media (max-width: 786px) {
    .main_loading{
        width: 100%;
    }
}
.rdd_container {
  width: 85%;
  height: 650px;
  /* background-color: cadetblue; */
  margin: 0 auto;
}
.rdd_container2 {
  width: 85%;
  height: 650px;
  /* background-color: cadetblue; */
  margin: 0 auto;
}

.rdc_text1 {
  width: 610px;
  height: 240px;
  text-align: center;
}

.rdc_text1 h5 {
  /* font: normal normal normal 70px/75px Styrene A Web; */
  /* font-size: 70px;
  font-weight: 400;*/
  /* font-family: 'Styrene A Web'; */
  font-family: 'Styrene A Web Reg';
  font-weight: 400;
  font-style: bold;
  font-size: 70px;
  letter-spacing: -2.59px;
  color: #E22222;
  text-transform: capitalize;
  opacity: 1;
  /* font-size: 70px; */
  /* font-weight: 500; */
}

.rdc_coll {
  /* width: 382px; */
  width: 65%;
  /* height: 22px; */
  height: 65%;
  text-align: center;
  /* font: normal normal medium 24px/1px Styrene A Web; */
  /* font-size: ; */
  letter-spacing: 0.05px;
  font-family: 'Styrene A Web Medium';
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.rdc_fill {
  width: 357px;
  height: 111px;
  text-align: center;
  /* font: normal normal medium 16px/22px Styrene A Web; */
  /* font-size: 16px; */
  /* font-weight: 400; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: 'Styrene A Web Reg';
}

.rdc_number {
  width: 184px;
  height: 8px;
  opacity: 1;
  margin: 0 auto;
}

.request-delivery {
  padding-top: 20px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  width: 100%;
  height: 1600px;
  /* background-color: red; */
  /* margin-bottom: 5rem; */
}

.request_imgdiv {
  width: 100%;
  height: 800px;
}

.rd-col2 {
  /* padding-left: 240px; */
  position: relative;
  top: -50%;
  width: 50%;
  height: 800px;
}

.rd-image1 {
  /* top: 1180px; */
  left: 70px;
  width: 370px;
  height: 500px;
  background: transparent url("../../assets/rd1.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
  top: -5%;
}

.rd-image2 {
  /* top: 50px; */
  /* top: -80%; */
  left: 130px;
  top: -450px;
  width: 400px;
  height: 500px;
  background: transparent url("../../assets/rd2.png") 0% 0% no-repeat padding-box;
  opacity: 0.92;
  z-index: 1;
  position: relative;
}

.rd-image3 {
  /* top: -265px; */
  left: 350px;
  top: -750px;
  width: 262px;
  height: 310px;
  background: transparent url("../../assets/rd3.png") 0% 0% no-repeat padding-box;
  opacity: 0.93;
  z-index: 1;
  position: relative;
}

.rd-text {
  color: #d8d8d8;
  text-align: center;
  letter-spacing: 2.59px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 370;
  font-size: 4rem;
  /* padding-top: 60px; */
}

.rd-text2 {
  /* padding-top: 80px; */
  padding-left: 120px;
}

.rd-text3 {
  /* padding-top: 30px; */
}

.rd-text3-para {
  /* padding-top: 25px; */
  overflow: hidden;
  padding-left: 90px;
  letter-spacing: 2px;
}

.request_imgdiv {
  margin-top: 25rem;
}

.request-delivery {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.request_right {
  width: 100%;
  margin-top: 50rem;
}

.request_right p {
  text-align: left;
}

.right1_text {
  width: 610px;
  height: 240px;
  text-align: center;
  font: normal normal normal 70px/75px Styrene A Web;
  letter-spacing: -2.59px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
  padding-top: 2rem;
}

.collection_box1 {
  width: 382px;
  height: 22px;
  text-align: center;
  font: normal normal medium 24px/1px Styrene A Web;
  letter-spacing: 0.05px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 7.5rem;
  font-size: 25px;
  margin-top: 5rem;
}

.collection_box2 {
  width: 357px;
  height: 111px;
  text-align: center;
  font: normal normal normal 16px/22px Styrene A Web;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 17px;
  margin-left: 7.5rem;
  margin-top: 3rem;
}

.number-row {
  display: block;
  padding-top: 80px;
  /* padding-left: 120px; */
  margin-left: 11rem;
}

.number-column {
  flex: 5%;
  padding-left: 20px;
  font: normal normal normal 11px/2px Founders Grotesk;
  font-size: medium;
}

.c2 {
  width: 65%;
  object-fit: contain;
  background-size: cover;
  /* padding-left: 3rem; */
  /* margin-left: 4rem; */
  /* margin-left: 3rem; */
  /* padding-top: 90%;
  padding-left: 10%; */
}

.rdc_1 {
  width: 430px;
  height: 600px;
  object-fit: contain;
  background-size: cover;
  opacity: 0.92;
}

.rdc_container {
  margin-right: 4rem;
}

@media (max-width: 786px) {
  .rdd_container2{
    display: block;
    height: fit-content;
  }
  .rdc_container{
    margin-right: 0px;
    width: 100%;
  }
  .rdc_text1{
    width: 100%;
    height: fit-content;
    margin-top: 20px;
  }
  .rdc_text1 h5{
    font-size: 16px;
    letter-spacing: 2px;
    margin-top: 10px;
  }
  .rdc_fill{
    width: 100%;
    height: fit-content;
    font-size: 10px;
    font-weight: 200 !important;
    margin-top: 10px;
  }
  .c2{
    width: 100%;
  }
  .rdd_carousal{
    height: fit-content !important;
  }
  .rdc_1{
    height: 250px;
  }
  .rdc_coll{
    margin-top: -30px;
  }
}
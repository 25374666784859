.privacyFix{
    width: 85%;
    margin: 0 auto;
    font-family: 'Styrene A Web Reg';
}
.privacy2 li{
   margin-left: 30px;
   
}

th{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.privacy11{
    font-size: 19px;
}
.privacy12{
    font-size: 24px;
}
.privacy13{
    font-size: 18px;
}
.privacy14{
    font-size: 24px;
}

@media (max-width: 786px) {
    .privacyFix{
        font-size: 12px;
    }

    .privacyFix p{
        font-size: 12px !important;
    }

    .privacy11{
        font-size: 15px;
    }
    .privacy12{
        font-size: 16px;
    }
    .privacy13{
        font-size: 11px;
    }
    .privacy14{
        font-size: 14px;
    }
}
.stick {
  position: sticky;
  top: 0;
  /* padding-bottom: 1rem; */
  z-index: 999;
}

.fix {
  position: sticky;
}
.menuText{
  padding-left: 10px;
  font-size: 14px;
  letter-spacing: 1.4px;
  font: normal normal normal 14px/30px Styrene A Web bold;
  /* font-weight: bold; */
}
.mainHeader{
  position: sticky;
  top: 0pc;
  z-index: 999;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  background-color: black;
  border-bottom: 1px solid red;
}

.center_logo {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  /* width: 100vw; */
  flex-basis: 100%;
}

/* ============================ */

.nav-menu {
  background-color: #fff;
  width: 60%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1000;
  transition: all 0.8s ease-in-out;
  overflow-y: hidden;
}

.nav-menu.active {
  left: 0;
  transition: all 0.8s ease-in-out;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.sn_image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.sn_image:hover {
  transform: scale(0.7);
  transition: all 0.5s ease-in-out;
}

.close_icon1 {
  width: 25px;
  height: 25px;
  background: transparent;
  opacity: 1;
  border-radius: 10rem;
  color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-left: 1.6rem;
  cursor: pointer;
}

.l_home22{
  color: black !important;
}

.l_home, .l_home1, .l_home2, .l_home3 {
  color: black !important;
  /* transition: opacity 5s ease-out; */
}

.dis_color {
  color: rgba(80, 78, 78, 0.3) !important;
}

.dis_color:hover {
  color: #000000 !important;
  transition: all 0.3s ease-in-out;
}

.l_home:hover {
  transition: all 0.3s ease-in-out;
}

.nvg1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: -150px 0px 0px 300px;
  justify-content: space-around;
  height: 350px;
  padding-top: 5rem;
}

.nvg9 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20rem;
}

.nvg_a {
  width: 100px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
}

.nvg_b {
  width: 190px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
  position: relative;
  display: inline-block;
}

.nvg_b-1 {
  width: 350px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
  /* margin-top: 2rem; */
  position: relative;
  display: inline-block;
}
.nvg_b-111 {
  width: 350px;
  text-align: left;
  font: normal normal normal 32px/19px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
  /* margin-top: 2rem; */
  position: relative;
  display: inline-block;
}

.nvg_b-2 {
  width: 190px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
  position: relative;
}

.nvg_c {
  width: 244px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
}

.nvg_d {
  width: 114px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
}

.nvg_e {
  width: 148px;
  height: 35px;
  text-align: left;
  font: normal normal normal 32px/49px Styrene A Web;
  letter-spacing: -0.02px;
  color: #0f0f0f;
  text-transform: capitalize;
  opacity: 1;
}

.sc_links h6:hover {
  text-decoration: underline;
  transition: all 0.2s ease-in;
  margin-left: 4px;
  color: rgb(46, 45, 45);
  font-weight: 500;
}

/* cart  */

.cart-menu {
  background-color: rgba(58, 57, 57, 0.8);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 1000;
  transition: all 0.8s ease-in-out;
  overflow-y: hidden;
}

.cart-menu.active {
  left: 0;
  transition: all 0.8s ease-in-out;
}

.cart-menu-items {
  width: 100%;
}

.cart-toggle {
  width: 100%;
  height: 80px;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sc_image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-color: #fff;
}

.cart_header {
  width: 90%;
  height: 50px;
  margin: 0 auto;
  margin-top: 2rem;
  border-bottom: 2px solid black;
}

.mainMenuClass{
  margin-top: 50px;
  margin-left: -85px;
}

.close_cart {
  width: 37px;
  height: 36px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 10rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cart_content {
  width: 90%;
  margin: 0 auto;
  margin-top: 4rem;
}

.MuiButton-label {
  font-size: 20px !important;
}

.check_btn {
  position: absolute;
  bottom: 10%;
}

/* table style */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: none;
  text-align: left;
  padding: 8px;
}

tr {
  border-bottom: 1px solid rgb(99, 97, 97);
  padding-bottom: 2rem;
  margin-top: 1rem;
}

/* .collection-content {
  display: none;
  position: relative ;
  background-color: #fff;
  width: 300px;
  height: 350px;
  z-index: 999;
} */

.cc_pro a {
  font-size: 14px;
  color: #111 !important;
  font-weight: 600 !important;
}

/* .collection-content a:hover {
  color: rgba(0, 0, 0, 0.9) !important;
} 
.nvg_b:hover .collection-content {
  display: block;
} */

.protext {
  font-weight: 600 !important;
}

/* menu animation */

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* border: 3px solid #fff; */
}

.menu-btn__burger {
  width: 50px;
  height: 6px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before, .menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 6px;
  background: rgb(194, 192, 192);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

/* ANIMATION */

.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.cl_ic {
  position: absolute;
  z-index: 999;
}

.nvg99{
  display: none;
  flex-direction: column;
  justify-content: space-around;
}

.raymond_logo {
  width: 200px;
  height: 50px;
  object-fit: contain;
}

.MuiList-padding {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.rightSideHeader{
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.75rem;
}
.profileIcon{
  padding-right: 1.5rem;
}
@media(max-width:500px) {
  .rightSideHeader{
    padding-right: 0px;
  }
  .profileIcon{
    padding-right: 0px;
  }
  .mainMenuClass{
    margin-left: -30px;
  }
}

@media(max-width:786px) {
  .close_icon1{
    margin-left: 20px;
  }
  .mainMenuClass{
    margin-top: 40px;
  }
  .navigations{
    margin-top: 20px !important;
  }
  .newHightClass{
    height: 30px;
  }
  .newHightClass a{
    font-size: 16px;
  }
  .mainHeader{
    height: 60px;
  }
  .nvg1{
    margin: 0px;
    padding-top: 0px;
    height: fit-content;
    padding-left: 30px;
  }
  .l_home{
    font-size: 20px;
  }
  .l_home1{
    font-size: 20px;
  }
  .l_home2{
    font-size: 20px;
  }
  .l_home3{
    font-size: 20px;
  }
  .nvg_a{
    height: 40px;
  }
  .nvg_b{
    height: 40px;
    font-size: 20px;
  }
  .nvg_c{
    height: 40px;
  }
  .nvg_d{
    height: 40px;
  }
  .nvg_e{
    height: 40px;
  }
  .nvg9{
    display: none;
  }
  .nvg99{
    display: flex;
    margin-left: 40px;
  }
}
.story {
  background-color: #f0f0f0;
  padding-top: 1rem;
}

.story_headtext {
  text-align: center;
  padding-top: 2.5rem;
}

.story_heading {
  /* font-weight: 500; */
  /* font: normal normal normal 32px/49px Styrene A Web; */
  font-family: 'Styrene A Web Reg';
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 2px;
  color: rgb(0, 0, 0);
}

.story_button {
  text-align: center;
  margin-top: 1.5rem;
}

.button_content {
  /* padding: 10px 20px; */
  font-size: 16px;
  font-family: "Styrene A Web Reg";
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  width: 300px;
  height: 40px;
  outline: none;
  border: 2px solid #111;
  border-radius: 10rem;
}

.story_images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1.5rem;
  width: 100%;
  gap: 0;
  grid-gap: 0;
  /* margin-left: 1.7rem; */
  padding-top: 40px;
  /* height: 690px; */
  /* margin: 0 auto; */
}

.story_img2 {
  /* height: 690px; */
  /* width:300px; */
}

.story_bottom {
  width: 70%;
  margin: auto;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 100px;
}

.bottom1_text {
  font-weight: 500;
  color: #111;
  letter-spacing: 3px;
  font-size: 26px;
  text-decoration: underline;
  line-height: -2px;
}

.bottom2_text {
  font-weight: 500;
  color: #111;
  font-size: 15px;
}

.return_icon {
  font-size: 20px;
}

.moving_arrow {
  height: 28px;
}

.btn1 {
  position: relative;
  /* width: 281px; */
  height: 38px;
  border: 2px solid #000000;
  opacity: 1;
  border-radius: 10rem;
  outline: none;
  cursor: pointer;
}

.btn-primary1 img:hover {
  animation: anime 0.5s infinite alternate;
}

.icon {
  position: absolute;
  /* left:18px; */
  right: -43%;
  top: 3px;
  font-size: 30px;
}

.btn-primary:hover {
  /* img.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
} */
  /* img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
} */
  /* @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translatex(20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translatex(20px); }
} */
}

@-webkit-keyframes anime {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translatex(5px);
  }
}

@keyframes anime {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translatex(5px);
  }
}

.story_image1 {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.story_image1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

.story_image1 img:hover {
  /* animation: scale 1s  ; */
  transform: scale(1.1);
  transition: all 1.5s ease-out;
}

.story_image2 {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.story_image2 img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  opacity: 1;
}

.story_image2 img:hover {
  /* animation: scale 1s  ; */
  transform: scale(1.1);
  transition: all 1.5s ease-out;
}

.story_image3 {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.story_image3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

.story_image3 img:hover {
  transform: scale(1.1);
  transition: all 1.5s ease-out;
}

.anim_text {
  animation: animT 1s;
}

@keyframes animT {
  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1);
  }
}

.story_btm {
  width: 80%;
  margin: 0 auto;
}

.st_btm1 {
  width: 505px;
  height: 198px;
  text-align: left;
  padding-left: 3rem;
}

.st_btm1 h2 {
  width: 505px;
  height: 198px;
  /* font: normal bold normal 32px/49px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  line-height: 49px;
  font-weight: 32px;
  letter-spacing: -0.02px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  line-height: -2px !important;
  text-underline-offset: 0.16em !important;
  text-decoration: underline;
  text-decoration-thickness: 2px !important;
}

.st_btm2 {
  width: 497px;
  height: 142px;
  text-align: left;
  padding-left: 3rem;
}

.st_btm2 p {
  font: normal normal normal 16px/22px Styrene A Web;
  font-family: "Styrene A Web Reg";
  line-height: 22px;
  font-weight: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.btm2_arr {
  width: 38px;
  height: 21px;
  opacity: 1;
}

.load_div {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 786px) {
  .button_content{
    font-size: 12px;
    width: fit-content;
    padding-left: 20px;
    padding-right: 50px;
  }
  .story_btm{
    width: 95%;
    display: block;
  }
  .st_btm1{
    height: fit-content;
    width: 90%;
    padding-left: 0px;
    margin-left: 5%;
  }
  .st_btm1 h2{
    width: 100%;
    text-align: center;
    height: fit-content;
    margin-bottom: 20px;
  }
  .st_btm2{
    height: fit-content;
    width: 90%;
    padding-left: 0px;
    margin-left: 5%;
  }
  .st_btm2 p{
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 200 !important;
    letter-spacing: 0px;
  }
  .text-semibold{
    font-size: 14px;
    line-height: 25px !important;
  }
  .story_images{
    margin-top: 0px;
    display: block !important;
    width: 80%;
    margin-left: 10%;
  }
  .story_headtext{
    padding-top: 0px;
  }
  .story_heading{
    font-size: 16px;
    width: 90%;
    margin-left: 5%;
  }
  .story_image1{
    display: none;
  }
  .story_image3{
    display: none;
  }
} 
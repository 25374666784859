.gl_product {
  /* height: 100vh;
    width: 100%; */
  background-color: #ffffff;
}

.top_text {
  width: 100%;
  height: 60px;
  text-align: center;
}

.mainProImage2{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vd_bcg {
  width: 1500px;
  height: 773px;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  background: #232a34;
  margin: 0 auto;
}

.top_para {
  width: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13px;
  text-align: center;
  margin: 0 auto;
  color: #000000;
  padding-top: 2rem;
  font-size: 12px;
  opacity: 1;
  text-decoration: underline;
  line-height: 2px;
}

.gl_mainImg {
  width: 100%;
  height: 773px;
  /* height: 90vh; */
  /* background: transparent 50% 0% no-repeat padding-box; */
  /* background-repeat: no-repeat;
  background-size: auto; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 1;
  margin: 0 auto;
}

.intro_top {
  height: 22px;
  text-align: center;
  position: relative;
  top: 33%;
  font-family: 'Styrene A Web Medium';
  letter-spacing: 0.04px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
}

.main_intro {
  height: 82px;
  text-align: center;
  font: normal normal normal 70px/75px font-family Styrene A Web Reg;
  font-size: 70px;
  line-height: 75px;
  font-family: 'Styrene A Web Reg';
  letter-spacing: -2.59px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  position: relative;
  top: 33.3%;
}

.intro_sub {
  /* width: 590px;
  position: relative;
  top: 35%;
  margin: 0 auto;
  height: 74px;
  text-align: center;
  font-family: "Styrene A Web Reg";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: #ffffff;
  opacity: 1;
  margin-top: 1rem; */

  width: 590px;
  position: relative;
  top: 34%;
  margin: 0 auto;
  height: 74px;
  text-align: center;
  font-family: 'Styrene A Web Light';
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.red_section {
  width: 600px;
  height: 13px;
  text-align: center;
  /* font: normal normal normal 11px/2px Founders Grotesk; */
  font-family: "Styrene A Web Reg";
  font-size: 11px;
  line-height: 2px;
  margin: 0 auto;
  /* padding-top: 13rem; */
  margin-top: 4rem;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}

.red_section h5 {
  letter-spacing: 0.99px;
  font-size: 12px;
  /* font-weight: 500; */
  color: #e22222;
}

.down_para {
  width: 1085px;
  height: 47px;
  text-align: center;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  letter-spacing: 1px;
  color: #000000;
  opacity: 1;
  padding-top: 1.7rem;
  margin: 0 auto;
  padding-bottom: -8rem;
}

.down_para h6 {
  font-weight: 500;
  /* font-size: 16px; */
}

.gp_div {
  width: 323px;
  height: 485px;
  margin: 0 auto;
}

.pd_div {
  width: 323px;
  height: 120px;
  margin: 0 auto;
}

.product_images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0 0 0;
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
}

.product_grid1 {
  width: 323px;
  height: 485px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.product_grid2 {
  width: 323px;
  height: 485px;
  background: transparent url("../../assets/collections/Component\ 111.png") 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.product_grid3 {
  width: 323px;
  height: 485px;
  background: transparent url("../../assets/collections/Component\ 112\ .png")
    0% 0% no-repeat padding-box;
  opacity: 1;
}

.product_grid4 {
  width: 323px;
  height: 485px;
  background: transparent url("../../assets/collections/Component\ 113.png") 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.product_details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin: 0 auto;
}

.product_details1,
.product_details2,
.product_details3,
.product_details4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product_name {
  width: 123px;
  height: 13px;
  text-align: center;
  font: normal normal bold 12px/23px Styrene A Web;
  letter-spacing: 0.16px;
  color: #252525;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 0.4rem;
}

.product_weight {
  width: 124px;
  height: 14px;
  text-align: center;
  font: normal normal normal 12px/23px Styrene A Web;
  letter-spacing: 0.16px;
  color: #999999;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 0.4rem;
}

.product_fabric {
  width: 150px;
  height: 15px;
  text-align: center;
  font: normal normal normal 12px/23px Styrene A Web;
  letter-spacing: 0.16px;
  color: #0b0b0b;
  text-transform: uppercase;
  opacity: 1;
}

.discover_section {
  width: 221px;
  height: 17px;
  text-align: center;
  margin: 0 auto;
  margin-top: 4.5rem;
  opacity: 1;
}

.discover_section h5 {
  /* font: normal normal bold 13px/24px Styrene A Web; */
  font-family: "Styrene A Web Black";
  letter-spacing: 0px;
  color: #000000;
  /* text-transform: uppercase; */
  text-decoration: underline;
  text-decoration-thickness: 2.5px;
  font-size: 13px;
}

.discover_para {
  width: 900px;
  height: 47px;
  text-align: center;
  opacity: 1;
  margin: 0 auto;
  margin-top: 2rem;
  padding-bottom: 100px;
}

.discover_para h5 {
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  padding-bottom: 10px;
  letter-spacing: 0px;
  font-weight: 600;
  color: #000000;
  font-size: 15px;
}

.video_section {
  width: 1366px;
  height: 773px;
  background: transparent url("../../assets/collections/Mask\ Group\ 235.png")
    0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 0 auto;
  margin-top: 4rem;
}

.ecovera-line {
  position: absolute;
  padding-top: 100px;
  left: 20%;
}

.overlay-text {
  position: relative;
  left: 17%;
  top: 20%;
  color: white;
}

.head-1 {
  font-family: "Styrene A Web Light";
  text-align: left;
  letter-spacing: 0.8px;
  color: #faf4ef;
  padding-bottom: 10px;
  font-size: 12px;
}

.sub-1 {
  padding-bottom: 10px;
  text-decoration: underline;
  font-family: "Styrene A Web Light";
  text-align: left;
  text-underline-position: under;
  font-size: 22px;
}

.cont-1 {
  padding-bottom: 60px;
  letter-spacing: 0.99px;
  font-family: "Styrene A Web Light";
  width: 170px;
  font-size: 15px;
}

.head-2 {
  text-align: left;
  letter-spacing: 0.99px;
  font-family: "Styrene A Web Light";
  color: #faf4ef;
  padding-bottom: 10px;
  font-size: 12px;
}

.sub-2 {
  padding-bottom: 10px;
  text-decoration: underline;
  font-family: "Styrene A Web Light";
  text-align: left;
  text-underline-position: under;
  font-size: 22px;
}

.cont-2 {
  padding-bottom: 60px;
  letter-spacing: 0.99px;
  font-family: "Styrene A Web Light";
  width: 170px;
  font-size: 15px;
}

.head-3 {
  text-align: left;
  letter-spacing: 0.99px;
  color: #faf4ef;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: "Styrene A Web Light";
}

.sub-3 {
  padding-bottom: 10px;
  text-decoration: underline;
  text-align: left;
  font-size: 22px;
  text-underline-position: under;
  font-family: "Styrene A Web Light";
}

.cont-3 {
  padding-bottom: 50px;
  letter-spacing: 0.99px;
  width: 170px;
  font-size: 15px;
  font-family: "Styrene A Web Light";
}

.ecovera_text {
  position: relative;
  top: 50%;
}

.ecovera-text .head {
  /* position: absolute;
    font-size: 12px;
    left: 60%;
    top: 300%;
    font-weight: 500;
    text-decoration: underline;
    text-align: left;
    text-underline-position: under; */
}

.ecovera-text .content {
  /* position: absolute;
    font-size: 18px;
    left: 60%;
    top: 305%;
    width: 400px; */
}

.ecovera-text .explore_more {
  /* position: absolute;
    left: 62%;
    top: 320%;
    width: 281px;
    height: 36px;
    border-radius: 18px;
    opacity: 1; */
  /* margin-right: -20rem; */
  margin: 0 auto;
  margin-top: 4rem;
}

.discover {
  position: absolute;
  top: 380%;
  left: 45%;
  letter-spacing: 0.1px;
}

.discover h4 {
  left: 50px;
  text-decoration: underline;
  text-underline-position: under;
}

.discover p {
  padding-top: 40px;
  color: black;
  letter-spacing: 0.5px;
  font-size: 20px;
}

.products-display-1 {
  position: absolute;
  top: 400%;
  left: 10%;
}

.products-display-2 {
  position: absolute;
  top: 470%;
  left: 10%;
}

/* ecover section */

.ecover_section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* width: 90%; */
  width: 85%;
  margin: 0 auto;
  margin-top: 6rem;
}

.ecover_left {
  background-color: tomato;
  width: 422px;
  height: 633px;
  background: transparent 0% 0% no-repeat padding-box;
  object-fit: contain;
  opacity: 1;
}

.ecov_right {
  width: 100%;
}

.ecr_tt {
  width: 210px;
  height: 17px;
  text-align: left;
}

.ecr_tt h5 {
  /* font: normal normal bold 13px/24px Styrene A Web; */
  font-family: "Styrene A Web Black";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-thickness: 2.5px;
}

.ecr_para {
  width: 497px;
  height: 142px;
  text-align: left;
}

.ecr_para h6 {
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
}

.ecr_para,
.ecr_tt,
.ecr_btn,
.ecr_carousal {
  margin-left: 9rem;
}

.ecr_carousal {
  /* width: 100%; */
  height: 200px;
  /* width: 750px; */
  /* background-color: rgb(240, 227, 227); */
  margin-top: 4rem;
}

.ec_slide img {
  width: 140px;
  height: 200px;
  opacity: 1;
}

.ecr_slide {
  /* width: 90%; */
}

.ecover_right {
  /* background-color: turquoise; */
  /* width: 422px; */
  height: 600px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.gr_ecovera {
  width: 203px;
  height: 17px;
  text-align: left;
  font: normal normal bold 13px/24px Styrene A Web;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
}

.gr_para {
  width: 497px;
  height: 142px;
  text-align: left;
  font: normal normal normal 16px/22px Styrene A Web;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 1rem;
}

.gr_button {
  width: 185px;
  height: 14px;
  text-align: center;
  font: normal normal normal 14px/18px Styrene A Web;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.right_top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;
}

.discover_collection {
  margin: 0 auto;
  margin-top: 5rem;
}

.dc_text {
  width: 221px;
  height: 17px;
  text-align: center;
  margin: 0 auto;
}

.dc_text h5 {
  /* font: normal normal bold 13px/24px Styrene A Web; */
  font-family: "Styrene A Web Black";
  text-decoration-thickness: 2px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
}

.dc_para {
  width: 310px;
  height: 26px;
  margin-top: 1rem;
  text-align: center;
  font: normal normal normal 22px/1px Cormorant Garamond;
  letter-spacing: -0.01px;
  color: #2c2b2b;
  text-transform: capitalize;
  opacity: 1;
  margin: 0 auto;
  margin-top: 1rem;
}

.eco_div {
  width: 700px;
  /* background-color: rgb(85, 25, 25); */
  height: 110px;
  opacity: 1;
  margin: 0 auto;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.ep {
  /* background-color: #111; */
}

.eco_product1,
.eco_product3,
.eco_product5 {
  /* background-color: tomato; */
}

.eco_product1,
.eco_product2,
.eco_product3,
.eco_product4,
.eco_product5,
.eco_product6 {
  width: 100px;
  height: 100px;
  opacity: 1;
}

.dropdown_section {
  width: 1366px;
  height: 34px;
  opacity: 1;
  background-color: tomato;
  margin: 0 auto;
  margin-top: 6rem;
}

.gl_coll {
  margin-top: 3rem !important;
}

@media (max-width: 786px) {
  .head-1{
    padding-top: 20px;
  }
  .overlay-text{
    left: 10%;
  }
  .gl_coll{
    margin-top: 1rem !important;
  }
  .dc_para{
    font-size: 18px;
  }
  .sub-1{
    font-size: 18px;
  }
  .sub-2{
    font-size: 18px;
  }
  .sub-3{
    font-size: 18px;
  }
  .ec_slide img {
    height: auto;
  }
  .discover_collection{
    margin-top: 2rem;
  }
  .eco_product1,.eco_product2,.eco_product3,.eco_product4,.eco_product5{
    height: auto;
    margin: 0px 0px !important;
    padding: 5px;
  }
  .gl_mainImg{
    padding-top: 0px;
    height: 275px;
    width: 100%;
  }
  .ecover_left{
    width: 100%;
    height: auto;
  }
  .ecover_section{
    margin-top: 50px;
  }
  .intro_sub{
    width: 95%;
    height: fit-content;
    top: 0%;
    font-size: 12px;
  }
  .red_section{
    width: 100%;
    height: fit-content;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". . ." ". . .";
  }
  .red_section h5{
    margin-bottom: 10px;
    font-size: 10px;
  }
  .intro_top{
    top: 10%;
  }
  .down_para{
    width: 100%;
    height: fit-content;
    padding-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
    border-bottom: 1px solid red;
  }
  .down_para h6{
    font-size: 10px;
    letter-spacing: 0.2px;
  }
  .discover_para{
    width: 90%;
    height: fit-content;
    padding-bottom: 30px;
  }
  .vd_bcg{
    width: 100%;
    height: 200px;
  }
  .ecover_section{
    display: block;
    width: 100%;
  }
  .ecr_tt{
    width: 100%;
    margin-left: 0px;
    text-align: center;
  }
  .ecr_para{
    width: 90%;
    padding-left: 5%;
    margin-left: 0px;
    text-align: center;    
    height: fit-content;
    margin-top: 20px !important;
  }
  .ecr_para h6{
    line-height: 15px;
    font-size: 10px;
    font-family: "Styrene A Web Reg";
  }
  .ecr_carousal{
    /* width: fit-content; */
    margin-left: 0px;
    margin-top: 20px;
  }
  .eco_div{
    margin-top: 0rem;
    display: flex;
    height: fit-content;
    width: 85%;
  }
  .ep{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0px;
  }
  .main_intro {
    height: fit-content;
    margin-bottom: 20px;
    width: 100%;
    font-size: 22px;
    top: 6%;
  }
  .mainProImage2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". . . .";
  }
  .ecr_carousal{
    height: fit-content;
  }
  .discover_section{
    margin-top: 0px;
  }
  .discover_para h5{
    font-size: 10px;
    font-weight: 300;
  }
  .discover_para{
    margin-top: 10px;
    padding-bottom: 10px;
  }
  .minus55MT{
    margin-top: -55px;
  }
}

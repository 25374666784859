.myOrder{
  font-size: 30px;
  font-family: "Styrene A Web Reg";
  padding-top: 20px;
}
.orderTime{
  font-size: 10px;
  font-family: "Styrene A Web Reg";
}
.orderStatus{
  font-size: 13px;
  font-family: "Styrene A Web Reg";
  font-weight: bold;
  letter-spacing: 1px;
}
.mainOrderData{
  margin-left: 1.5rem;
}
.itemNameOrder{
  font-size: 18px;
  font-family: "Styrene A Web Reg"; 
  letter-spacing: 1px;
}
.noOrderText{
 width: 100%;
 text-align: center;
 font-family: "Styrene A Web Reg";
 font-size: 14px;
 letter-spacing: 2px;
}


@media (max-width: 786px) {
  .mainOrderData{
    margin-left: 0px;
  }
  .orderTime{
    text-align: center;
  }
  .orderStatus{
    text-align: center;
  }
}
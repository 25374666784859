body {
  background: #faf4ef;
}

.back-to-homepage {
  display: flex;
  margin-top: 0px;
  font-size: 15px;
  color: red;
  text-decoration-line: underline;
  font-family: "Styrene A Web Medium";
  align-items: center;
}

.down-icon {
  padding-bottom: 30px;
  margin-top: 10px;
}

.create-acc {
  padding: 20px;
  font-family: "Styrene A Web Medium";
}

input[type="text"], .Phone {
  width: 23.1rem;
  /* margin: 15px 0; */
  box-sizing: border-box;
  border: none;
  /* border-bottom: 1.5px solid black; */
  font-size: 14px;
  outline: none;
  background-color: transparent;
}

input[type="email"], .Phone {
  width: 23.1rem;
  /* margin: 15px 0; */
  box-sizing: border-box;
  border: none;
  /* border-bottom: 1.5px solid black; */
  font-size: 14px;
  outline: none;
  background-color: transparent;
  font-family: "Styrene A Web Reg";
}

input[type="password"], .Phone {
  width: 23.1rem;
  /* margin: 15px 0; */
  box-sizing: border-box;
  border: none;
  /* border-bottom: 1.5px solid black; */
  font-size: 14px;
  outline: none;
  background-color: transparent;
  font-family: "Styrene A Web Reg";
}

.input-fields {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-family: "Styrene A Web Reg";
}

.register-header {
  font-size: 5rem;
  color: red;
  font-family: "Styrene A Web Reg";
}

.terms-conditions {
  padding: 10px;
  font-family: "Styrene A Web Medium";
}

.register-button {
  outline: none;
  color: white;
  width: 50%;
  font-size: 14px;
  border-radius: 18px;
  background: black;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 17px;
  border: none;
  margin-bottom: 35px;
  font-family: "Styrene A Web Reg";
}

input[type="text"]#subdomaintwo {
  -webkit-appearance: none !important;
  text-align: left;
  box-sizing: border-box;
  border: none;
  /* border-bottom: 1.5px solid black; */
  font-size: 14px;
  outline: none;
  width: 85px;
  font-family: "Styrene A Web Medium";
}

.profile-icon {
  color: red;
  font-size: 20px;
}

label {
  padding-top: 20px;
  padding-bottom: 10px;
}

.text-black {
  color: black !important;
  font-family: "Styrene A Web Reg";
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #111 !important;
  color: #fff;
  font-size: 1.0625em;
}

.form_email {
  /* width: 550px; */
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1.5px solid #111;
}

.formMainView{
  width: 40vw;
}

.role {
  margin-top: 15px;
}
  
@media(max-width:786px) {
  .formMainView{
    width: 80vw;
  }
  .register-header{
    font-size: 20px;
  }
  .vert-move{
    height: 40px !important;
    margin-top: 20px;
  }
  .create-acc{
    padding-top: 0px;
  }
  .register-button{
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
  }
}
@media(max-width:386px) {
  .formMainView{
    width: 95vw;
  }
}

.faq {
    background-color: #ECECE8;
}

.faq_mainImg {
    width: 648px;
    height: 864px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
}

.newFullWidth{
    width: 61%;
    padding-left: 19%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.text-header {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: #E22222;
    text-transform: capitalize;
    font-size: 100px;
    letter-spacing: 3.48px;
    width: 644px;
    height: 120px;
    text-align: center;
    /* font: normal normal normal 94px/121px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 93px;
    line-height: 121px;
    letter-spacing: -3.48px;
    color: #E22222;
    text-transform: capitalize;
    opacity: 1;
}

.sub-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: white;
    text-transform: capitalize;
    font-size: 10px;
    letter-spacing: 3.48px;
    width: 141px;
    height: 13px;
    text-align: center;
    /* font: normal normal normal 11px/2px Founders Grotesk; */
    font-family: "Styrene A Web Light";
    font-size: 11px;
    line-height: 2px;
    letter-spacing: 0.99px;
    color: #FAF4EF;
    opacity: 1;
    padding-top: 25px;
}

.emailPrimary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: #E22222;
    text-transform: lowercase;
    font-size: 40px;
    letter-spacing: 0.02px;
    text-decoration: underline;
    width: 534px;
    height: 39px;
    text-align: center;
    /* font: normal normal normal 32px/49px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 32px;
    line-height: 49px;
    letter-spacing: -0.02px;
    color: #E22222;
    text-transform: lowercase;
    opacity: 1;
}

.start-collab {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: white;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.99px;
    width: 141px;
    height: 13px;
    text-align: center;
    /* font: normal normal normal 11px/2px Founders Grotesk; */
    font-family: "Styrene A Web Light";
    font-size: 11px;
    line-height: 2px;
    padding-top: 25px;
    letter-spacing: 0.99px;
    color: #FAF4EF;
    opacity: 1;
}

.email-2 {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: #E22222;
    text-transform: lowercase;
    font-size: 40px;
    letter-spacing: 0.02px;
    text-decoration: underline;
    width: 500px;
    height: 39px;
    text-align: center;
    /* font: normal normal normal 32px/49px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 32px;
    line-height: 49px;
    letter-spacing: -0.02px;
    opacity: 1;
}

.sub-header {
    position: absolute;
    top: 90%;
    left: 34%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: #E22222;
    text-transform: capitalize;
    font-size: 100px;
    letter-spacing: 3.48px;
    width: 624px;
    height: 241px;
    text-align: left;
    /* font: normal normal normal 94px/121px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 94px;
    line-height: 121px;
    letter-spacing: -3.48px;
    color: #E22222;
    text-transform: capitalize;
    opacity: 1;
}

.content-1 {
    padding-top: 3rem;
    padding-left: 19%;
    background-color: transparent;
    font-size: 20px;
    color: #0F0F0F;
    /* width: 479px; */
    height: 94px;
    text-align: left;
    font: normal normal normal 16px/22px Styrene A Web;
    letter-spacing: 0px;
    color: #0F0F0F;
    opacity: 1;
}

.form-1 {
    /* position: relative; */
    /* top: 110%; */
    /* left: 50%; */
    opacity: 1;
    width: 542px;
    height: 244px;
    opacity: 1;
    margin-top: 2rem;
    /* margin-top: 8rem; */
}

.name, .email, .subject {
    outline: 0;
    border-width: 0 0 2px;
    border-color: black;
    border-bottom: 2px solid black !important;
    /* padding: 5px 0px; */
    font-size: 18px;
    /* padding-top: 50px; */
    background-color: #ECECE8;
}

input:focus {
    border-color: #E22222;
}

.row {
    display: grid;
}

.in_name1 {
    height: 23px;
    border: 1px solid red;
}

#input-text {
    /* padding: 0px 40px 6px 0px; */
    padding-right: 3rem;
    font-size: 18px;
    outline: 0;
    border-bottom: 2px solid black;
}

#message {
    font-size: 20px;
}

.info {
    padding-top: 7rem;
    padding-left: 19%;
    display: flex;
    flex-direction: column;
}

.info-text {
    padding: 40px 0px 0px 0px;
    font-size: 20px;
    outline: 0;
    text-decoration: underline;
    width: 122px;
    height: 18px;
    text-align: left;
    font: normal normal normal 14px/18px Styrene A Web;
    letter-spacing: 0px;
    color: #0F0F0F;
    text-transform: capitalize;
    opacity: 1;
}

.inf_3 {
    width: 211px;
    height: 18px;
    text-align: left;
    font: normal normal normal 14px/18px Styrene A Web;
    letter-spacing: 0px;
    color: #0F0F0F;
    text-transform: capitalize;
    opacity: 1;
}

textarea {
    /* border: 1px solid #111; */
    width: 100%;
    padding-top: 15px;
    background-color: #ECECE8;
}

.FAQ {
    text-transform: capitalize;
    padding-top: 200px;
    font-size: 90px;
    padding-left: 19%;
    letter-spacing: 3.48px;
    color: black;
    font-family: "Styrene A Web Reg";
}

.bottom-image {
    padding-top: 70px;
    height: 300px;
    width: 500px;
    margin: 0 auto;
    background-color: #ECECE8;
}

.bottom-text {
    font-size: 16px;
    font-weight: 600;
    color: red;
    background-color: transparent;
    font-family: "Styrene A Web Reg";
    text-align: center;
    margin-top: -240px;
}

.inpContact {
    color: Black;
    font-family: "Styrene A Web Reg";
}

.bottom-text2 {
    position: absolute;
    top: 63%;
    /* left: 5%; */
    color: red;
    background-color: transparent;
    font-size: 28px;
    text-align: center;
    font-family: "Styrene A Web Reg";
}

.arrow-up {
    height: 70px;
    position: absolute;
    top: 90%;
    left: 0%;
    background-color: transparent;
}

.bt_imgLast {
    background-color: #ECECE8;
    width: 100%;
}

.faq_footer {
    padding-top: 3rem;
    background-color: #ECECE8;
}

.makeStyles-expand_icon-5 {
    color: red !important;
}

.MuiSvgIcon-root {
    color: red !important;
}
.mainInFAQ{
    display: flex;
    justify-content: space-between;
}

.faq_parent {
    width: 100vw;
    height: 420px;
    padding: 0px 10%;
    margin-top: 4rem;
}

.fp_left {
    /* margin-left: 4rem; */
}

.faq_woven {
    margin-top: 20px;
    width: 479px;
    height: 94px;
    text-align: left;
    /* font: normal normal normal 16px/22px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #0F0F0F;
    opacity: 1;
}

.faq_boxes {
    margin-top: 4rem;
}

.l_chat {
    width: 122px;
    height: 18px;
    text-align: left;
    /* font: normal normal normal 14px/18px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #0F0F0F;
    text-transform: capitalize;
    opacity: 1;
    text-decoration: underline;
    margin-top: 2.5rem;
    text-underline-offset: 0.25em;
    text-decoration-thickness: 3px !important;
}

.c_ray {
    width: 205px;
    height: 25px;
    text-align: left;
    /* font: normal normal normal 14px/18px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #0F0F0F;
    text-transform: lowercase;
    opacity: 1;
    text-decoration: underline;
    margin-top: 3.5rem;
    text-underline-offset: 0.30em;
    text-decoration-thickness: 3px !important;
}

.freq_ques {
    width: 211px;
    height: 18px;
    text-align: left;
    /* font: normal normal normal 14px/18px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #0F0F0F;
    text-transform: capitalize;
    opacity: 1;
    margin-top: 3.5rem;
    text-decoration: underline;
    text-underline-offset: 0.25em;
    text-decoration-thickness: 3px !important;
}

.sendBtn {
    width: 200px;
    height: 40px;
    background-color: rgb(32, 32, 32);
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0.8rem;
    border-radius: 2px;
}

.sendBtn:hover {
    border: 2px solid black;
    transition: all 0.3s ease-in-out;
    background: #0f0f0f;
}

/* responsive design */

@media (max-width:480px) {
    .addressImage{
        width: 100vw;
        height: 100vw;
    }
    .bottom-text{
        width: 100vw;
    }
    .faq_mainImg {
        width: 324px;
        height: 432px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2rem;
    }
    .text-header {
        width: 322px;
        height: 60px;
        text-align: center;
        font-size: 46px;
        top: 20%;
    }
    .sub-text {
        width: 110px;
        height: 12px;
        font-size: 9px !important;
        margin-top: 0.7rem;
        top: 29%;
    }
    .emailPrimary {
        font-size: 17px !important;
        width: 267px;
        height: 17px;
        top: 32%;
    }
    .start-collab {
        width: 111px;
        height: 12px;
        font-size: 9px !important;
        top: 39.2%;
    }
    .email-2 {
        font-size: 17px !important;
        width: 267px;
        height: 17px;
        top: 41%;
    }
    .sub-header {
        position: absolute;
        top: 58%;
        /* left: 34%; */
        left: 60%;
        width: 400px;
        height: 220px;
        text-align: left;
        /* font: normal normal normal 94px/121px Styrene A Web; */
        letter-spacing: -3.48px;
        color: #E22222;
        font-size: 57px;
        text-transform: capitalize;
        opacity: 1;
    }
}

.form_gr {
    border-bottom: 2px solid #111;
    margin-bottom: 3rem !important;
}

@media (max-width:768px) {
    .makeStyles-root-11{
        width: 90%;
        padding-left: 0px;
        margin-right: 0px;
    }
    .newFullWidth{
        width: 100%;
        padding: 40px 5%;
        padding-top: 20px;
    }
    .bottom-image{
        padding-top: 0px;
        margin-bottom: 25px;
    }
    .text-header{
        font-size: 24px !important;
        letter-spacing: 2px;
    }
    .l_chat{
        margin-top: 20px;
    }
    .c_ray{
        margin-top: 20px;
    }
    .FAQ{
        font-size: 12vw;
        padding-left: 5%;
        padding-top: 80px;
        font-size: 16px;
        text-align: center;
        padding-left: 0px;
    }
    .mainInFAQ{
        display: block;
    }
    .faq_boxes{
        margin-top: 0px;
    }
    .form-1{
        width: 100%;
        height: fit-content;
        margin-top: 40px;
    }
    .faq_woven{
        width: 100%;
        height: fit-content;
        margin-top: 0px;
        font-size: 12px;
    }
    .faq_parent{
        height: fit-content;
    }
    .sub-header{
        font-size: 20px !important;
        line-height: 40px !important;
        margin-top: 60px;
        letter-spacing: 0px;
        height: fit-content !important;
        display: none !important;
    }
}

/* @media (max-width:480px) {

} */
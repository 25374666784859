@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.col_head {
    width: 751px;
    height: 90px;
    text-align: center;
    margin: 0 auto;
    margin-top: 2.5rem;
}

.col_head h1 {
    letter-spacing: -2.59px;
    color: #E22222;
    text-transform: capitalize;
    /* font: normal normal normal 70px/81px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 70px;
    line-height: 81px;
    opacity: 1;
    font-size: 70px;
}

.col_headLeft {
    width: 510px;
    /* height: 198px; */
    text-align: left;
}

.col_headLeft h5 {
    /* font: normal normal normal 32px/49px Styrene A Web; */
    font-family: "Styrene A Web Light";
    font-size: 29px;
    line-height: 49px;
    text-underline-offset: 0.16em;
    letter-spacing: -0.07px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
    text-decoration: underline;
    font-weight: 600;
}

.col_headRight {
    width: 497px;
    /* height: 142px; */
    text-align: left;
}

.col_headRight h6 {
    /* font: normal normal normal 16px/22px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 1rem;
}

.coll_div {
    width: 98%;
    margin: 0 auto;
    margin-top: 4rem;
}

.coll_demo {
    width: 98%;
    margin: 0 auto;
    margin-top: 14rem;
}

.col_container {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    margin-bottom: 4rem;
}

.coll_img {
    width: 323px;
    height: 486px;
}

.coll_name {
    width: 200px;
    height: 13px;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.coll_name h5 {
    /* font: normal normal bold 12px/23px Styrene A Web; */
    font-family: "Styrene A Web Medium";
    letter-spacing: 0.16px;
    color: #252525;
    text-transform: uppercase;
    opacity: 1;
    font-size: 12px;
}

.coll_weight {
    width: 124px;
    height: 14px;
    text-align: center;
    /* font: normal normal normal 12px/23px Styrene A Web; */
    font-family: "Styrene A Web Reg";
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.16px;
    color: #999999;
    text-transform: uppercase;
    opacity: 1;
}

.coll_id {
    /* width: 250px; */
    height: 15px;
    text-align: center;
    /* font: normal normal normal 12px/23px Styrene A Web; */
    font-family: "Styrene A Web Medium";
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.16px;
    color: #0B0B0B;
    text-transform: uppercase;
    opacity: 1;
}
.filterMAIN{
    display: none;
}
.filterTEXT{
    font-size: 14px;
    background-color: black;
    color: white;
    font-family: Styrene A Web Reg;
    padding: 8px 0px;
    text-align: center;
    width: 120px;
    margin-left: 10px;
    cursor: pointer;
}
.CFTEXT{
    font-size: 14px;
    background-color: black;
    color: white;
    font-family: Styrene A Web Reg;
    padding: 8px 0px;
    text-align: center;
    width: 120px;
    margin-left: 10px;
    cursor: pointer;
}
.filterGridSYSTEM{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    /* gap: 0px 0px; */
    grid-template-areas: ". . ." ". . ." ". . .";
    width: 90vw;
}

.drop_down {
    width: 95%;
    height: 23px;
    border-bottom: 2px solid #E22222;
    opacity: 1;
    margin: 0 auto;
    margin-top: 3rem;
}

.dp_cast {
    /* width: 27px; */
    /* height: 13px; */
    text-align: left;
    font-size: 11px;
    color: #000000;
    opacity: 1;
}

.down-a {
    font-size: 12px;
    margin-top: 0.2rem;
}

.dp_weight, .dp_weave, .dp_color, .dp_gender {
    /* width: 40px; */
    letter-spacing: 0.99px;
    /* height: 13px; */
    text-align: left;
    font-size: 11px;
    color: #000000;
    opacity: 1;
}

.dp_composition {
    width: 90px;
    letter-spacing: 0.99px;
    /* height: 13px; */
    text-align: left;
    font-size: 11px;
    color: #000000;
    opacity: 1;
}

select {
    background-color: transparent !important;
}

.container1 {
    width: 8%;
    margin: 30px;
}

.select-container {
    padding: 10px;
    position: relative;
    border-radius: 3px;
    background: #fff;
}

.select-container select {
    /* width: 100%; */
    border: 0;
    background: transparent;
    font-size: 11px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-container:after {
    content: 'keyboard_arrow_down';
    font-family: 'material icons';
    font-size: 11px;
    color: red !important;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.back_next {
    width: 96%;
    height: 20px;
    margin: 0 auto;
}

.dd_right {
    width: 250px;
}

.allHead {
    /* padding-top: 2rem; */
    padding-bottom: 1rem;
}

.dp_clear {
    width: 75px;
    letter-spacing: 0.99px;
    height: 17px;
    text-align: left;
    font-size: 11px;
    margin-bottom: 1rem;
    background-color: #f1efef;
    color: #000000;
    opacity: 1;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.dp_clear:hover {
    color: rgba(0, 0, 0, 0.7);
}

.headingInAllCollection{
    /* text-align: center; */
    width: 100%;
    padding-left: 12.5%;
    font-size: 40px;
    letter-spacing: 1.48px;
    font-weight: 900;
    color: black;
    font-family: "Styrene A Web Reg";
    margin-bottom: 25px;
    margin-top: 40px;
}

@media (max-width: 786px) {
    .coll_img{
        height: auto;
    }
    .coll_demo{
        grid-column-gap: 10px;
    }
    .filterMAIN{
        display: block;
        /* align-items: center; */
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
    }
    .coll_div {
        grid-column-gap: 10px;
    }
    .drop_down{
        /* width: 100%; */
        display: none;
        /* height: fit-content; */
    }
    .newFilterDiv{
        display: block;
        width: 100%;
    }
    .dp_cast{
        margin-right: 0px !important;
        display: flex;
        /* justify-content: center; */
        height: 30px;
    }
    .dp_weight{
        margin-right: 0px !important;
        display: flex;
        /* justify-content: center; */
        height: 30px;
    }
    .dp_weave{
        margin-right: 0px !important;
        display: flex;
        /* justify-content: center; */
        height: 30px;
    }
    .dp_color{
        margin-right: 0px !important;
        display: flex;
        /* justify-content: center; */
        height: 30px;
    }
    .dp_composition{
        margin-right: 0px !important;
        display: flex;
        /* justify-content: center; */
        height: 30px;
        width: 100%;
        padding-left: 0px;
    }
    .dd_right{
        width: 100%;
        display: block;
    }
    .dp_gender{
        margin-right: 0px !important;
        display: flex;
        /* justify-content: center; */
        height: 30px;
        width: 100%;
        padding-left: 0px;
    }
    .dp_clear{
        margin-right: 0px !important;
        display: flex;
        justify-content: center;
        height: 30px;
        width: 30%;
        margin-left: 35%;
        margin-top: 20px;
    }
    .headingInAllCollection{
        width: 90%;
        padding-left: 0px;
        text-align: center;
        margin-left: 5%;
        font-size: 18px;
        margin-top: 25px;
        margin-bottom: 15px;
    }
    .allHead{
        display: block;
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 0px 10px !important;
        width: 100vw;
    }
    .col_headLeft{
        width: 100%;
        text-align: center;
    }
    .col_headLeft h5{
        font-size: 12px;
        line-height: 23px;
    }
    .col_headRight{
        width: 100%;
        margin-top: 10px !important;
        text-align: center;
    }
    .col_headRight h6{
        line-height: 23px;
        font-size: 10px;
    }
    .newDivInAll{
        display: block;
    }
    .coll_name{
        margin-bottom: 0px !important;
    }
    .coll_name h5{
        font-size: 10px;
    }
    .coll_weight{
        font-size: 10px;
        margin-top: 0px !important;
    }
    .coll_id{
        font-size: 8px;
    }
}

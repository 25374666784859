.cartHead {
    width: 100%;
    padding: 0px 20px;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    background-color: white;
}

.cartSummary {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-top: 2rem;
    background-color: white;
}

.parent_div {
    height: 100%;
    max-height: 100vh !important;
    overflow-y: auto;
    background-color: transparent;
}

.cs_btn {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    height: 40px;
    background: #000;
    color: #fff;
}

.cs_bts {
    width: 100%;
    background-color: white;
}

.cs_img img {
    height: 100px;
    width: 100px;
    background-size: cover;
    object-fit: contain;
}

.del_pro {
    font-size: 20px;
}

.del_pro:hover {
    /* animation: shake 0.5s; */
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    color: red;
    /* animation-iteration-count: infinite; */
}

.cs_btn:hover {
    background-color: rgb(34, 33, 33);
    transition: all 0.3s ease-in-out;
}

.empty_cart {
    width: 100%;
    padding: 0px 0px;
    padding-top: 1rem;
    background-color: white;
}

.empty_img {
    width: 150px;
    height: 150px;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
}

a {
    text-decoration: none !important;
    color: red !important;
}

:root {
    text-underline-position: under;
}
.App{
    overflow: hidden;
    overflow-y: auto;
}
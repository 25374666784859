.green-line {
  width: 100%;
  opacity: 1;
  padding-bottom: 1rem;
  margin: 0 auto;
  margin-top: 6rem;
}

.mySlides {
  display: none;
}

/* .green-line-text1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 17%;
}

.gl-t1 {
    color: #E22222;
    letter-spacing: 0.04px;
    font-weight: 500;
    font-size: 1.3rem;
    font: normal normal medium 19px/1px Styrene A Web;
}

.green-line-text2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 350;
    font-size: 3rem;
    letter-spacing: 2.59px;
    font: 70px/75px;
}

.gl-t2 {
    color: white;
}

.green-line-text3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.gl-t3 {
    color: white;
} */

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: none !important;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.cr_heading {
  width: 133px;
  height: 22px;
  text-align: center;
  /* font: normal normal medium 19px/1px Styrene A Web; */
  font-family: 'Styrene A Web Medium';
  letter-spacing: 0.04px;
  color: #e22222;
  text-transform: capitalize;
  opacity: 1;
}

.cr_gLine {
  /* width: 536px; */
  height: 82px;
  text-align: center;
  font: normal normal normal 70px/75px font-family Styrene A Web Reg;
  font-size: 70px;
  line-height: 75px;
  font-family: 'Styrene A Web Reg';
  letter-spacing: -2.59px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.cr_gSub {
  width: 590px;
  height: 74px;
  text-align: center;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: 'Styrene A Web Light';
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.cr_caption {
  display: grid;
  place-items: center;
  padding-bottom: 30%;
  transition: all 0.3s ease-in-out;
  animation: scale1 2s;
}

.cr_img {
  height: 850px;
  width: 98%;
  object-fit: cover;
}

.carousel-indicators li {
  content: attr(1);
}

.nmbr {
  display: flex;
  width: 50%;
  margin: 0 auto;
  align-items: center;
  margin-left: 550px;
  position: absolute;
  /* top: 20%; */
  /* bottom: 5%; */
  color: red;
  left: -5%;
}

/* .number-slide{
    position: relative;
    top: 40%;
} */

.nm_1 {
  margin-left: 3rem;
}

.nm_2 {
  margin-left: 3rem;
}

.nm_3 {
  margin-left: 3rem;
}

.carousel-indicators {
  content: "1";
}

.carousel-indicators ol {
  width: 100%;
}

.carousel-indicators li {
  height: 3px !important;
  border-radius: 0px !important;
  width: 25%;
}

.green-line {
  height: 850px;
}


@media (max-width: 786px) {
  .green-line{
    height: fit-content;
    margin-top: 30px;
  }
  .cr_img{
    /* width: 100% !important; */
    height: 230px;
  }
  .carousel-caption{
    width: 100%;
    left: 0px !important;
    padding-top: 0px !important;
  }
  .cr_caption{
    display: block;
  }
  .cr_heading{
    width: 100%;
    text-align: center;
  }
  .cr_gLine{
    height: fit-content;
    width: 90%;
    margin-left: 5%;
    font-size: 23px;
    letter-spacing: 2px;
    line-height: 45px;
  }
  .cr_gSub{
    height: fit-content;
    width: 90%;
    margin-left: 5%;
    margin-top: 0px;
    font-size: 10px;
  }
  .cr_caption{
    padding-bottom: 0px;
  }
  
}
.product_view {
  width: 100%;
  height: 100%;
}

.imgdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 750px;
  overflow-y: scroll;
  margin-left: 1rem;
}
.imgdiv2 {
  display: none !important;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 750px;
  overflow-y: scroll;
  margin-left: 1rem;
}

.product_header {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconColor1{
  color: black !important;
}

.ph_1 {
  width: 128px;
  height: 13px;
  text-align: center;
  font: normal normal normal 11px/2px Founders Grotesk;
  letter-spacing: 0.99px;
  color: #000000;
  opacity: 1;
  margin: 0 auto;
  text-decoration: underline;
}

.pro_head2 {
  width: 100%;
  height: 70px;
  /* background-color: rgb(87, 84, 84); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid red;
}

.phd_1 {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.p_col {
  width: 124px;
  height: 20px;
  text-align: center;
  /* font: normal normal normal 15px/12px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #e22222;
  text-transform: uppercase;
  opacity: 1;
}

.p_for {
  width: 70px;
  height: 20px;
  text-align: center;
  /* font: normal normal normal 15px/12px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #e22222;
  text-transform: uppercase;
  opacity: 1;
}

.p_man {
  width: 40px;
  height: 20px;
  text-align: center;
  /* font: normal normal normal 15px/12px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #e22222;
  text-transform: uppercase;
  opacity: 1;
}

.p_view {
  width: 84px;
  height: 20px;
  text-align: center;
  /* font: normal normal normal 15px/12px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #e22222;
  text-transform: uppercase;
  opacity: 1;
}

.product_details {
  width: 100%;
  /* height: 915px; */
  background: #babcb2 0% 0% no-repeat padding-box;
  opacity: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.pro_images {
  border-right: 1px solid red;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; */
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  display: none !important;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  display: none !important;
}

.pro_smallImg {
  width: 64px;
  height: 96px;
  opacity: 1;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.sm_Img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prop {
  height: 96px;
  object-fit: contain;
  width: 64px;
}

.pro_bigImg {
  width: 450px;
  height: 800px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.pro_desc {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.pro_desc1 {
  border-bottom: 1px solid rgb(245, 9, 9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}

.pro_mainDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: rgb(95, 92, 92); */
}

.pro_name {
  width: 500px;
  height: 22px;
  text-align: left;
  margin-top: 1rem;
}

.pro_name h5 {
  /* font: normal normal medium 19px/1px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  font-size: 19px;
  line-height: 1px;
  letter-spacing: 0.04px;
  color: #000000;
  text-transform: capitalize !important;
  opacity: 1;
}

.pro_price {
  width: 100px;
  height: 19px;
  text-align: left;
  font: normal normal normal 16px/24px Styrene A Web;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 1rem;
}

.pro_spec {
  width: 518px;
  height: 122px;
  text-align: left;
  /* font: normal normal normal 16px/22px Styrene A Web; */
  font-family: "Styrene A Web Medium";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 1rem;
  /* padding-bottom: 4rem; */
}

.pro_descButton {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_btn1, .add_btn2 {
  padding: 8px 30px;
  width: 250px;
  /* height: 25px; */
  text-align: center;
  /* font: normal normal normal 16px/24px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background-color: black;
}

.dec, .inc {
  width: 30px;
}

.pro_desc9{
  display: none;
}
.pro_desc8{
  display: block;
}

.pro_desc2 {
  /* display: grid;
    grid-template-rows: repeat(1fr, 8); */
  /* width: 80%; */
  /* margin: 0 auto; */
  /* margin-right: 2rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  border-bottom: 1px solid red;
}

.desc2_quality, .desc2_weight, .desc2_width, .desc2_color, .desc2_finish, .desc2_composition, .desc2_weave {
  width: 100%;
  border-bottom: 1px solid red;
  padding-bottom: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 7rem;
  font-family: "Styrene A Web Medium";
  padding-top: 13px;
}

.we_d {
  font-size: 16px;
}

.desc2_type {
  width: 100%;
  /* border-bottom: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 7rem;
  font-family: "Styrene A Web Medium";
  padding-top: 14px;
  padding-bottom: 14px;
}

.pro_recommend {
  width: 200px;
  height: 17px;
  text-align: center;
  /* font: normal normal bold 13px/24px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 13px;
}

.explore_col_text {
  width: 183px;
  height: 18px;
  text-align: center;
  /* font: normal normal bold 13px/24px Styrene A Web; */
  font-family: "Styrene A Web Reg";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 13px;
}

/* product view */

.right-nav .image-gallery-svg {
  display: none !important;
}

.image-gallery-fullscreen-button .image-gallery-svg {
  display: none !important;
}

.image-gallery-thumbnail-image {}

.sm_images {
  width: 200px;
  height: 250px;
}

/* carousal function */

.fluid {
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
}

.recommend_demo {
  margin-top: -9.5rem;
}

@media (min-width: 768px) {
  .fluid {
    flex-direction: row;
  }
}

.imgtag {
  margin-bottom: 10px;
  width: 100px;
  height: 1000px;
  cursor: pointer;
}

.zoom {
  height: 600px;
  width: 450px;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

.msg {
  font-size: 15px !important;
  color: #000000 !important;
  font-weight: 600;
}

.zom_1 {
  background-size: cover;
  object-fit: contain;
  width: 530px;
  height: 750px;
}

@media (max-width: 786px) {
  .product_details{
    height: fit-content !important;
    display: block;
  }
  .pro_name{
    width: 100vw;
    text-align: center;
    height: fit-content;
  }
  .pro_name h5{
    font-size: 20px;
  }
  .pro_desc{
    display: block;
  }
  .pro_spec{
    width: 95vw;
    margin-left: 2.5vw;
    text-align: center;
    height: fit-content;
    line-height: 15px;
    font-size: 12px;
    font-family: "Styrene A Web Reg";
    font-weight: 200 !important;
  }
  .pro_descButton{
    display: flex;
    justify-content: space-between;
    width: 90vw;
    margin-left: 5vw;
    align-items: center;
  }
  .add_btn1, .add_btn2{
    font-size: 12px;
    width: fit-content;
    margin-bottom: 20px;
    white-space: nowrap;
    padding: 8px 18px;
  }
  .desc2_quality{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
  }
  .desc2_weight{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
  }
  .desc2_width{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
  }
  .desc2_color{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
  }
  .desc2_finish{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
  }
  .desc2_composition{
    width: 100%;
    padding: 15px 20%;
    /* padding-left: 0px;
    padding-top: 10px; */
  }
  .desc2_weave{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
  }
  .desc2_type{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .text-lg{
    text-align: center !important;
    width: 100%;
    font-size: 12px;
  }
  .imgdiv{
    display: none !important;
  }
  .imgdiv2{
    margin-left: 0px;
    height: 150px;
    flex-direction: row;
    width: 90%;
    margin-left: 5%;
    display: flex !important;
    margin-top: 15px;
  }
  .imgtag{
    height: 150px;
    margin-right: 20px;
    /* width: 90%; */
  }
  .fluid{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .zoo{
    margin-left: 5%;
    width: 90%;
    height: fit-content;
  }
  .zom_1{
    height: fit-content;
  }
  .pro_images{
    border-right-width: 0px;
    padding-top: 20px !important;
  }
  .pro_desc9{
    display: block;
    border-bottom: 1px solid red;
  }
  .pro_desc8{
    display: none;
  }
  .MuiAccordionDetails-root{
    display: block !important;
  }
}